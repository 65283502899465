import { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import { DataGridPro } from "@mui/x-data-grid-pro";
import useLanguage from "../../../../../../context/LanguageProvider";
import { transformDataCancelRoutes } from "./transformDataCancelRoutes";

function CustomToolbar() {
  if (toolbar) {
    return (
      <GridToolbarContainer
        sx={{
          color: "#F90D4A",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "40px",
          width: "100%",
        }}
      >
        {/* @ts-ignore */}
        <GridToolbarColumnsButton
          sx={{
            display: "flex",
            color: "var(--button-cancel)",
            width: "90px",
            background: "transparent",
            margin: "2px 5px 2px 18px",
            fontSize: "14px",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              color: "#F90D4A",
            },
          }}
          placeholder=""
        ></GridToolbarColumnsButton>
        {/* @ts-ignore */}
        <GridToolbarFilterButton
          sx={{
            display: "flex",
            color: "var(--button-cancel)",
            width: "90px",
            background: "transparent",
            margin: "2px 5px 2px 15px",
            fontSize: "14px",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              color: "#F90D4A",
            },
            "& .MuiFormControl.root": {
              backgroundColor: "yellow",
              color: "white",
            },
          }}
          placeholder=""
        />
        {/* @ts-ignore */}
        <GridToolbarDensitySelector
          sx={{
            display: "flex",
            color: "var(--button-cancel)",
            width: "90px",
            background: "transparent",
            margin: "2px 5px 2px 15px",
            fontSize: "14px",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              color: "#F90D4A",
            },
          }}
          placeholder=""
        />
        <GridToolbarExport
          sx={{
            display: "none",
            color: "var(--button-cancel)",
            width: "90px",
            background: "transparent",
            margin: "2px 5px 2px 15px",
            fontSize: "14px",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              color: "#F90D4A",
            },
          }}
        />
      </GridToolbarContainer>
    );
  } else {
    return <></>;
  }
}

const Step2NewCancelRouteModal = ({
  setSelectedRoutes,
  tableRoutesData,
  selectedRoutes,
  selectedCancelRoutesStep3,
  setSelectedCancelRoutesStep3,
  informationsColumns,
}: any) => {
  const { t } = useLanguage();
  const [rowsPreview, setRowsPreview] = useState<any[]>([]);
  const [columnsPreview, setColumnsPreview] = useState<
    GridColDef<any, any, any>[]
  >([
    {
      field: "",
      headerClassName: "es_header_column",
      headerName: "",
      width: 100,
    },
  ]);

  useEffect(() => {
    if (tableRoutesData && tableRoutesData.length > 0 && informationsColumns) {
      const { columns, rows } = transformDataCancelRoutes(
        tableRoutesData,
        tableRoutesData,
        informationsColumns
      );
      setColumnsPreview(columns);
      setRowsPreview(rows);
    } else {
      setColumnsPreview([]);
      setRowsPreview([]);
    }
  }, [tableRoutesData, informationsColumns]);

  return (
    <>
      <Typography
        sx={{
          width: "100%",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {t(
          "simulatorTexts.canceledRoutes.step2NewCancelRouteModal.routesSelection"
        )}
      </Typography>
      <Stack
        sx={{
          width: "1000px",
          pt: 2,
          height: "400px",
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "var(--bg-secondary)",
          },
          "& .MuiDataGrid-columnHeadersInner span": {
            color: "white",
          },
        }}
      >
        <DataGridPro
          rows={rowsPreview}
          columns={columnsPreview}
          rowsPerPageOptions={[5, 10, 20]}
          rowHeight={35}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            panel: {
              sx: {
                [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                  display: "none",
                },
              },
            },
          }}
          disableColumnMenu
          hideFooter
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectedRoutes}
          onSelectionModelChange={(newSelectionModel) => {
            if (selectedCancelRoutesStep3.length > 0) {
              setSelectedRoutes(
                selectedCancelRoutesStep3.map((route) => route._id)
              );
              setSelectedCancelRoutesStep3([]);
            } else {
              setSelectedRoutes(newSelectionModel);
            }
          }}
        />
      </Stack>
    </>
  );
};

export default Step2NewCancelRouteModal;
