import { ELEMENTS_URL, TABLAS_URL, TABLA_ARCHIVO } from "../../../api/axios";
import { Message } from "../../criterias/configCriteria/types";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ComponentLayout from "../../layout/ComponentLayout";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import data from "./data.json";
import MessageBlock from "../../forms/MessageBlock";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Step1Tables from "../shared/Step1Tables";
import Step2Tables from "../shared/Step2Tables";
import StepperButtons from "../../forms/StepperButtons";
import Typography from "@mui/material/Typography";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import useSingleSelect from "../../../hooks/useSingleSelect";
import {
  arrow_back_icon_styles,
  form_subtitle_styles,
} from "../../../styles/app-styles";

const ConfigTablesAnalysis = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const newElementConfig = localStorage.getItem("newElementConfig");
  const tabValueEndpoint = localStorage.getItem("tabValueEndpoint")
    ? localStorage.getItem("tabValueEndpoint")
    : undefined;
  const salesForecastView = localStorage.getItem("salesForecastView")
    ? localStorage.getItem("salesForecastView")
    : undefined;
  const simulationView = localStorage.getItem("simulationView")
    ? localStorage.getItem("simulationView")
    : undefined;
  const configMode = localStorage.getItem("configMode");
  const table = JSON.parse(localStorage.getItem("cardInfo") as string);
  const [openConfirmationTableModal, toggleConfirmationTableModal] =
    useDialog();
  const [message, setMessage] = useState<Message>({
    type: "success",
    text: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [idTable, setIdTable] = useState(
    configMode === "EDIT" && table ? table.id : undefined
  );
  const [tableName, setTableName] = useState(
    configMode === "EDIT" && table
      ? table?.nombre
      : configMode === "COPY" && table
      ? table?.nombre + "_copy"
      : ""
  );
  const [rowsLimit, setRowsLimit] = useState<number | string | undefined>(
    (configMode === "EDIT" || configMode === "COPY") && table
      ? table?.limitar_resultados
      : undefined
  );

  const { data: dataGetTables, refetch: refetchTables } = useApiQuery(
    `${TABLAS_URL}?tipo=${tabValueEndpoint}`,
    false,
    t("tablesManagement.getTablesError")
  );

  useEffect(() => {
    if ((configMode === "EDIT" || configMode === "COPY") && table && table.id) {
      getTable(`${TABLAS_URL}?tipo=${tabValueEndpoint}/${table.id}`);
    } else if (configMode === "EDIT" || configMode === "COPY") {
      navigate(t("/administracion-dashboard"), {
        state: { tab: "analisis/tablas" },
        replace: false,
      });
    }
  }, []);

  /********************************************* Stepper *********************************************/

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRestart = () => {
    console.log("preguntar si borrar todo y al confirmar borrar todo");
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /********************************************* POST y PUT *******************************************/

  const [fileSelectedOption, setFileSelectedOption, fileOptionsChangeHandler] =
    useSingleSelect(
      undefined,
      configMode === "EDIT" && table ? table?.archivo : undefined
    );

  useEffect(() => {
    if (newElementConfig) {
      setFileSelectedOption(JSON.parse(newElementConfig)?.archivo);
    }
  }, [newElementConfig]);

  const handlePostTable = (fileSelectedOption) => {
    if (!idTable) {
      if (fileSelectedOption) {
        postTable(undefined, {
          nombre: tableName,
          archivo: fileSelectedOption.value,
        });
      }
    } else {
      if (fileSelectedOption) {
        putTable(`${TABLAS_URL}${idTable}`, {
          nombre: tableName,
          archivo: fileSelectedOption.value,
        });
      }
    }
  };

  const { callApi: getTable, data: dataGetTable } = useApi(
    `${TABLAS_URL}`,
    "GET",
    t("indicatorsManagement.editIndicator.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  const onSuccessPost = (dataTable) => {
    setIdTable(dataTable.id);
    getTable(`${TABLAS_URL}${dataTable.id}`);
    refetchTables();
  };

  const { isLoading: isLoadingPostTable, callApi: postTable } = useApi(
    `${TABLAS_URL}`,
    "POST",
    t("tablesManagement.addTable.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const onSuccessPut = (dataTable) => {
    getTable(`${TABLAS_URL}${dataTable.id}`);
  };

  const { isLoading: isLoadingPutTable, callApi: putTable } = useApi(
    `${TABLAS_URL}?tipo=${tabValueEndpoint}`,
    "PUT",
    t("tablesManagement.editTable.codes"),
    undefined,
    onSuccessPut,
    undefined,
    true
  );

  const onSuccessPostElement = () => {
    if (newElementConfig && JSON.parse(newElementConfig).url) {
      localStorage.removeItem("tabValueEndpoint");
      localStorage.removeItem("previousUrl");
      navigate(JSON.parse(newElementConfig).url);
    }
  };
  const { isLoading: isLoadingPostNewElement, callApi: postNewElement } =
    useApi(
      "",
      "POST",
      t("dashboard.newElement.codes"),
      undefined,
      onSuccessPostElement
    );

  const handleFinish = (data: any) => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardInfo");
    if (newElementConfig && JSON.parse(newElementConfig).url && data) {
      localStorage.setItem("previousUrl", "/configuracion/analisis/tabla");
      const element = JSON.parse(newElementConfig);
      const newElement = {
        tipo: element.tipo.value,
        alto: element.alto,
        ancho: element.ancho,
        pk_elemento: data.id,
      };
      if (tabValueEndpoint && (salesForecastView || simulationView)) {
        postNewElement(
          `simulador/vistas/${salesForecastView || simulationView}/elementos`,
          newElement
        );
      } else {
        postNewElement(ELEMENTS_URL(element.viewTabValue), newElement);
      }
    } else {
      navigate(t("/administracion-dashboard"), {
        state: { tab: "analisis/tablas" },
        replace: false,
      });
    }
  };

  const { isLoading: isLoadingPutFinishTable, callApi: putFinishTable } =
    useApi(
      `${TABLAS_URL}?tipo=${tabValueEndpoint}`,
      "PUT",
      t("tablesManagement.editTable.codes"),
      undefined,
      handleFinish,
      undefined,
      true
    );

  const sendTable = () => {
    const completedInfo = {
      nombre: tableName,
      limitar_resultados: rowsLimit,
    };
    if (table?.id) {
      putFinishTable(TABLA_ARCHIVO(table.id), completedInfo);
    } else if (idTable) {
      putFinishTable(TABLA_ARCHIVO(idTable), completedInfo);
    }
    toggleConfirmationTableModal();
  };

  /********************************************* Cierre y limpieza ************************************/

  const closeAndClean = () => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardInfo");
    if (newElementConfig && JSON.parse(newElementConfig).url) {
      navigate(JSON.parse(newElementConfig).url);
      localStorage.setItem("previousUrl", "/configuracion/analisis/tabla");
    } else {
      navigate(t("/administracion-dashboard"), {
        state: { tab: "analisis/tabla" },
        replace: false,
      });
    }
  };

  return (
    <ComponentLayout
      title={
        tabValueEndpoint === "ANALISIS"
          ? t(
              "indicatorsManagementTexts.indicatorsManagement.indicatorConfigTableAnalysis"
            )
          : tabValueEndpoint === "SIMULACION"
          ? t(
              "indicatorsManagementTexts.indicatorsManagement.indicatorConfigTableSimulation"
            )
          : tabValueEndpoint === "SALES_FORECAST"
          ? t(
              "indicatorsManagementTexts.indicatorsManagement.indicatorConfigTableSalesForecast"
            )
          : ""
      }
      icon={
        <ArrowBackIcon sx={arrow_back_icon_styles} onClick={closeAndClean} />
      }
    >
      <SimpleBackdrop
        open={
          isLoadingPostTable ||
          isLoadingPutTable ||
          isLoadingPostNewElement ||
          isLoadingPutFinishTable
        }
        message={
          configMode === "CREATE" || configMode === "COPY"
            ? t("indicatorsManagementTexts.configTables.creatingTable")
            : t("indicatorsManagementTexts.configTables.modifyingTable")
        }
      />
      <ConfirmationModal
        open={openConfirmationTableModal}
        handleClose={toggleConfirmationTableModal}
        handleAccept={sendTable}
        message={
          configMode === "EDIT"
            ? t("indicatorsManagementTexts.configTables.editTheTable")
            : t("indicatorsManagementTexts.configTables.createTheTable")
        }
        title={
          configMode === "EDIT"
            ? t("indicatorsManagementTexts.configTables.editTable")
            : t("indicatorsManagementTexts.configTables.createTable")
        }
      />

      <Stack
        width={"100%"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        minHeight={"68vh"}
      >
        <Stack
          width={"100%"}
          minHeight={"68vh"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          {activeStep === 0 && (
            <>
              <Typography
                sx={{ ...form_subtitle_styles, margin: "0 0 10px 0" }}
              >
                {data.tableSteps[0]}
              </Typography>
              <MessageBlock message={message} />
              <Step1Tables
                setMessage={setMessage}
                fileType={tabValueEndpoint}
                defaultElement={table}
                message={message}
                dataGetTables={dataGetTables}
                tableName={tableName}
                setTableName={setTableName}
                dataGetTable={dataGetTable}
                fileSelectedOption={fileSelectedOption}
                fileOptionsChangeHandler={fileOptionsChangeHandler}
                getTable={getTable}
                idTable={idTable}
                handlePostTable={handlePostTable}
              />
            </>
          )}
          {activeStep === 1 && (
            <>
              <Typography
                sx={{ ...form_subtitle_styles, margin: "0 0 10px 0" }}
              >
                {data.tableSteps[1]}
              </Typography>
              <Step2Tables
                rowsLimit={rowsLimit}
                setRowsLimit={setRowsLimit}
                idTable={idTable}
              />
            </>
          )}
        </Stack>
        <Stack width="100%" alignSelf={"flex-end"} alignItems={"center"}>
          <StepperButtons
            handleBack={handleBack}
            handleNext={handleNext}
            handleRestart={handleRestart}
            onFinishClick={() => toggleConfirmationTableModal()}
            isBackButtonDisabled={activeStep === 0}
            isFinishButtonDisabled={
              message?.type === "error" ||
              !fileSelectedOption ||
              activeStep === 0
            }
            isNextButtonDisabled={
              activeStep === 1 ||
              message?.type === "error" ||
              !fileSelectedOption
            }
          />
        </Stack>
      </Stack>
    </ComponentLayout>
  );
};

export default ConfigTablesAnalysis;
