import { arrow_back_icon_styles } from "../../../styles/app-styles";
import { ExternalFilter, Filter, IdOption, Message } from "../../criterias/configCriteria/types";
import { GET_INDICADORES_GRUPOS_DETALLE, INDICADORES_ARCHIVOS, INDICADOR_ARCHIVO } from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useRef, useState } from "react";
import { useGetCriteriaFileColumn } from "../../criterias/configCriteria/api";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../../buttons/Button";
import ComponentLayout from "../../layout/ComponentLayout";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import CustomStepper from "../../Stepper/CustomStepper";
import data from "./data.json";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Step1Indicators from "../shared/Step1Indicators";
import Step2 from "../../criterias/configCriteria/Step2";
import Step4 from "../../criterias/configCriteria/Step4";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import useSingleSelect from "../../../hooks/useSingleSelect";
import { Tooltip } from "@mui/material";

const ConfigIndicatorsFiles = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const {
    data: indicators,
    /* isLoading: isLoadingKpis,
    isError: isErrorKpis,
    refetch: refetchIndicators, */
  } = useApiQuery(INDICADORES_ARCHIVOS, false, t("report.getKpis.error"));
  const {
    data: dataGetFileColumn,
  } = useGetCriteriaFileColumn();
  const dataGetindicatorFunctions = data.functions
  const { data: dataGetGroups, refetch: refetchGetGroups } = useApiQuery(
    GET_INDICADORES_GRUPOS_DETALLE(false),
    false,
    t('indicatorsManagement.addGroup.error')
  );
  const configMode = localStorage.getItem("configMode");
  const indicator = JSON.parse(localStorage.getItem("cardInfo") as string);

  const onSuccessPost = () => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardnInfo");
    navigate(t("/administracion-dashboard"), {
      state: { tab: "archivo/indicadores" },
      replace: false,
    });
  }

  const { isLoading: isLoadingPostIndicator, callApi: postIndicator } = useApi(
    INDICADORES_ARCHIVOS,
    "POST",
    t('indicatorsManagement.addIndicator.codes'),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const { isLoading: isLoadingPutIndicator, callApi: putIndicator } = useApi(
    INDICADOR_ARCHIVO,
    "PUT",
    t('indicatorsManagement.editIndicator.codes'),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const onFileColumn = () => {
    setColumnSelectedOptions(undefined);
    columnSelectRef?.current?.clearValue();
    setDenominatorSelectedOptions(undefined);
    denominatorSelectRef?.current?.clearValue();
  };

  const [message, setMessage] = useState<Message>();

  //Step 1
  const columnSelectRef: any = useRef(null);
  const denominatorSelectRef: any = useRef(null);
  const indicatorNameRef: any = useRef(null);
  const fileSelectRef: any = useRef(null);
  const groupRef: any = useRef(null);
  const unityRef: any = useRef(null);
  const functionSelectRef: any = useRef(null);
  const [indicatorFormComplete, setIndicatorFormComplete] = useState(false);
  const [indicatorName, setIndicatorName] = useState(
    configMode === "EDIT" ? indicator?.nombre : ""
  );
  const [activeStep, setActiveStep] = useState(0);
  const [
    unitySelectedOptions,
    setUnitySelectedOptions,
    unityOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? {
          value: indicator?.unidad_medida,
          label:
            indicator?.unidad_medida?.charAt(0).toUpperCase() +
            indicator?.unidad_medida?.slice(1),
        }
      : undefined
  );

  const [groupSelectedOptions, setGroupSelectedOptions, groupChangeHandler] =
    useSingleSelect(
      undefined,
      configMode === "EDIT" || configMode === "COPY"
        ? {
            value: indicator?.grupo?.value,
            label: indicator?.grupo?.label,
          }
        : undefined
    );

  const [
    fileSelectedOptions,
    setFileSelectedOptions,
    fileOptionsChangeHandler,
  ] = useSingleSelect(
    () => onFileColumn(),
    configMode === "EDIT" || configMode === "COPY"
      ? indicator?.archivo
      : undefined
  );
  const [
    columnSelectedOptions,
    setColumnSelectedOptions,
    columnOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? indicator?.columna
      : undefined
  );
  const [
    denominatorSelectedOptions,
    setDenominatorSelectedOptions,
    denominatorOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? indicator?.columna_divisor
      : undefined
  );
  const [
    functionSelectedOptions,
    setFunctionSelectedOptions,
    functionOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? { value: indicator?.funcion, label: indicator?.funcion }
      : undefined
  );

  const onColumnChange = () => {
    setSelectedValues([]);
    setValuesSelectedOptions(undefined);
  };

  const [activeIndicator, setActiveIndicator] = useState(
    configMode === "EDIT" || configMode === "COPY" ? indicator?.activo : true
  );

  /* Step 2 */
  const [
    conditionSelectedOptions,
    setConditionSelectedOptions,
    conditionOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnFilterSelectedOptions,
    setColumnFilterSelectedOptions,
    columnFilterOptionsChangeHandler,
  ] = useSingleSelect(() => onColumnChange());
  const [valuesSelectedOptions, setValuesSelectedOptions] =
    useState<IdOption[]>();
  const [selectedValues, setSelectedValues] = useState<string[] | null>([]);
  const [
    filterTypeSelectedOptions,
    setFilterTypeSelectedOptions,
    filterTypeOptionsChangeHandler,
  ] = useSingleSelect();
  const [filters, setFilters] = useState<Filter[]>();

  const onExternalColumnChange = () => {
    setSelectedExternalValues([]);
    setValuesExternalSelectedOptions(undefined);
  };

  //Step 3
  const externalFileSelectRef: any = useRef(null);
  const externalColumnSelectRef: any = useRef(null);
  const columnFileSelectRef: any = useRef(null);

  const onFileExternalColumnChange = () => {
    setExternalFilters([]);
    setExternalColumnSelectedOptions(undefined);
    setColumnFileSelectedOptions(undefined);
    externalColumnSelectRef.current.clearValue();
    columnFileSelectRef.current.clearValue();
  };

  const [
    externalFileSelectedOptions,
    setExternalFileSelectedOptions,
    externalFileOptionsChangeHandler,
  ] = useSingleSelect(
    () => onFileExternalColumnChange(),
    configMode === "EDIT" || configMode === "COPY"
      ? indicator?.archivo_externo_validacion
      : undefined
  );
  const [
    externalColumnSelectedOptions,
    setExternalColumnSelectedOptions,
    externalColumnOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnFileSelectedOptions,
    setColumnFileSelectedOptions,
    columnFileOptionsChangeHandler,
  ] = useSingleSelect();
  const [externalFilters, setExternalFilters] = useState<ExternalFilter[]>();
  const [
    conditionExternalSelectedOptions,
    setConditionExternalSelectedOptions,
    conditionExternalOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnFilterExternalSelectedOptions,
    setColumnFilterExternalSelectedOptions,
    columnFilterExternalOptionsChangeHandler,
  ] = useSingleSelect(() => onExternalColumnChange());
  const [valuesExternalSelectedOptions, setValuesExternalSelectedOptions] =
    useState<IdOption[]>();
  const [selectedExternalValues, setSelectedExternalValues] = useState<
    string[] | null
  >([]);
  const [
    filterTypeExternalSelectedOptions,
    setFilterTypeExternalSelectedOptions,
    filterTypeExternalOptionsChangeHandler,
  ] = useSingleSelect();
  const [filtersExternal, setFiltersExternal] = useState<Filter[]>();
  const [validationsStep3Completed, setValidationsStep3Completed] = useState(
    configMode === "EDIT" || configMode === "COPY" ? true : false
  );

  const [openindicatorModal, setOpenindicatorModal] = useState(false);

  useEffect(() => {
    if (configMode === "EDIT" || configMode === "COPY") {
      const prepareFilters = (externo: boolean) =>
        indicator?.indicador_archivo_columna_filtra?.filter((item: any) => item.externo === externo)
          .map((columnIndicator: any) => {
            return {
              id: columnIndicator.columna_id,
              condition: indicator.operacion_filtro_aplicacion === "AND" ? "y" : "ó",
              column: {
                value: columnIndicator.columna_id,
                label: columnIndicator.columna,
              },
              values: columnIndicator.indicador_archivo_valor_filtra.map(
                (valor: any) => {
                  return { value: valor.valor, label: valor.valor };
                }
              ),
              filterType: columnIndicator.excluir ? t("indicatorsManagementTexts.configIndicators.exclude") : t("indicatorsManagementTexts.configIndicators.include"),
            };
          });

      const prepareUnions = (externo: boolean) =>
        indicator?.indicador_archivo_columna_valida
          ?.filter((item: any) => item.externo === externo)
          .map((columnIndicator: any) => {
            if (externo) {
              return {
                id: `${columnIndicator.indicador_columna_archivo_base_id}-${columnIndicator.indicador_columna_gastos_o_externo_id}`,
                columnFile: {
                  value: columnIndicator.indicador_columna_archivo_base_id,
                  label: columnIndicator.indicador_columna_archivo_base,
                },
                externalColumn: {
                  value: columnIndicator.indicador_columna_gastos_o_externo_id,
                  label: columnIndicator.indicador_columna_gastos_o_externo,
                },
                externalFile: indicator.archivo_externo_validacion,
              };
            } else {
              return {
                id: `${columnIndicator.indicador_columna_archivo_base_id}-${columnIndicator.indicador_columna_gastos_o_externo_id}`,
                expensesColumn: {
                  value: columnIndicator.indicador_columna_gastos_o_externo_id,
                  label: columnIndicator.indicador_columna_gastos_o_externo,
                },
                column: {
                  value: columnIndicator.indicador_columna_archivo_base_id,
                  label: columnIndicator.indicador_columna_archivo_base,
                },
              };
            }
          });
      setFilters(prepareFilters(false));
      setFiltersExternal(prepareFilters(true));
      setExternalFilters(prepareUnions(true));
    } else {
      handleRestart();
    }
  }, []);

  //crear un useEffect que esté a la escucha de indicatorName, y si ya existe ese nombre en la lista de indicadores, entonces mostrar un mensaje de error
  useEffect(() => {
    setMessage(undefined);
    if (indicatorName) {
      const indicatorNameExists =
        indicators && indicators?.find(
          (indicator: any) =>
            indicator?.nombre?.toLowerCase() === indicatorName.toLowerCase()
        ) ||
        indicatorName === t("indicatorsManagementTexts.configIndicators.totalInExpense") ||
        indicatorName === t("indicatorsManagementTexts.configIndicators.totalInResult") ||
        indicatorName === t("indicatorsManagementTexts.configIndicators.totalTransferred") ||
        indicatorName === t("indicatorsManagementTexts.configIndicators.bucketPeriodsWithError");
      if (
        indicatorNameExists &&
        (configMode === "CREATE" || configMode === "COPY")
      ) {
        setMessage({
          type: "error",
          text: t("indicatorsManagementTexts.configIndicators.nameIndicatorExists"),
        });
      } else if (
        indicatorNameExists &&
        configMode === "EDIT" &&
        indicator?.nombre?.toLowerCase() !== indicatorName.toLowerCase()
      ) {
        setMessage({
          type: "error",
          text: t("indicatorsManagementTexts.configIndicators.nameIndicatorExists"),
        });
      } else {
        setMessage(undefined);
      }
    }
  }, [indicatorName]);

  const handleRestart = () => {
    setIndicatorName("");
    setUnitySelectedOptions(undefined);
    setFileSelectedOptions(undefined);
    setGroupSelectedOptions(undefined);
    setColumnSelectedOptions(undefined);
    setDenominatorSelectedOptions(undefined);
    setFunctionSelectedOptions(undefined);
    setIndicatorFormComplete(false);
    setActiveStep(0);
    indicatorNameRef.value = "";
    unityRef.current?.clearValue();
    groupRef.current?.clearValue();
    fileSelectRef.current?.clearValue();
    functionSelectRef.current?.clearValue();
    denominatorSelectRef.current?.clearValue();

    //Step 2
    setConditionSelectedOptions(undefined);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions(undefined);
    setFilterTypeSelectedOptions(undefined);
    setFilters(undefined);

    //Step3
    setExternalFileSelectedOptions(undefined);
    setExternalColumnSelectedOptions(undefined);
    setColumnFileSelectedOptions(undefined);
    setExternalFilters(undefined);
    setConditionExternalSelectedOptions(undefined);
    setColumnFilterExternalSelectedOptions(undefined);
    setValuesExternalSelectedOptions(undefined);
    setFilterTypeExternalSelectedOptions(undefined);
    setFiltersExternal(undefined);
  };

  const handleCloseindicatorModal = () => {
    setOpenindicatorModal(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const sendIndicator = () => {
    const step2_indicator = filters
      ? filters.map((uniqueFilter: Filter) => {
          return {
            columna: uniqueFilter.column.value,
            excluir: uniqueFilter.filterType === t("indicatorsManagementTexts.configIndicators.exclude") ? true : false,
            externo: false,
            indicador_archivo_valor_filtra: uniqueFilter.values
              .map((value: any) => {
                if (value.value !== "") {
                  return { valor: value.value };
                } else {
                  return null;
                }
              })
              .filter((value: any) => value !== null),
          };
        })
      : [];

    const step3_indicator = filtersExternal
      ? filtersExternal.map((uniqueFilter: Filter) => {
          return {
            columna: uniqueFilter.column.value,
            excluir: uniqueFilter.filterType === t("indicatorsManagementTexts.configIndicators.exclude") ? true : false,
            externo: true,
            indicador_archivo_valor_filtra: uniqueFilter.values
              .map((value: any) => {
                if (value.value !== "") {
                  return { valor: value.value };
                } else {
                  return null;
                }
              })
              .filter((value: any) => value !== null),
          };
        })
      : [];

    const step3_validation = externalFilters
      ? externalFilters.map((filter) => {
          return {
            indicador_columna_gastos_o_externo: filter.externalColumn.value,
            indicador_columna_archivo_base: filter.columnFile.value,
            externo: true,
          };
        })
      : [];

    const completedInfo = {
      nombre: indicatorName,
      archivo: fileSelectedOptions?.value,
      columna: columnSelectedOptions?.value,
      columna_divisor: denominatorSelectedOptions?.value,
      funcion: functionSelectedOptions?.value,
      unidad_medida: unitySelectedOptions?.value,
      grupo: groupSelectedOptions?.value,
      activo: activeIndicator,
      operacion_filtro_aplicacion: filters
        ? filters[0]?.condition === "ó"
          ? "OR"
          : "AND"
        : "",
      operacion_filtro_externo: filtersExternal
        ? filtersExternal[0]?.condition === "ó"
          ? "OR"
          : "AND"
        : "",
      indicador_archivo_columna_filtra: [
        ...step2_indicator,
        ...step3_indicator,
      ],
      indicador_archivo_columna_valida: [...step3_validation],
    };
    
    if (configMode === "CREATE" || configMode === "COPY") {
      postIndicator(undefined, completedInfo);
    } else {
      putIndicator(INDICADOR_ARCHIVO(indicator.id), completedInfo);
    }
    handleCloseindicatorModal();
  };

  const closeAndClean = () => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardInfo");
    navigate(t("/administracion-dashboard"), {
      state: { tab: "archivo/indicadores" },
      replace: false,
    });
  };

  return (
    <ComponentLayout
      title={t("indicatorsManagementTexts.indicatorsManagement.indicatorConfigFile")}
      icon={
        <ArrowBackIcon sx={arrow_back_icon_styles} onClick={closeAndClean} />
      }
    >
      <SimpleBackdrop
        open={isLoadingPostIndicator || isLoadingPutIndicator}
        message={
          configMode === "CREATE" || configMode === "COPY"
            ? t("indicatorsManagementTexts.configIndicators.creatingIndicator")
            : t("indicatorsManagementTexts.configIndicators.modifyingIndicator")
        }
      />  
      <ConfirmationModal
        open={openindicatorModal}
        handleClose={handleCloseindicatorModal}
        handleAccept={sendIndicator}
        message={
          configMode === "EDIT" ? t("indicatorsManagementTexts.configIndicators.editTheIndicator")
          : t("indicatorsManagementTexts.configIndicators.createTheIndicator")
        }
        title={configMode === "EDIT" ? t("indicatorsManagementTexts.configIndicators.editIndicator")
        : t("indicatorsManagementTexts.configIndicators.createIndicator")}
      />
      <CustomStepper activeStep={activeStep} data={data} />
      {activeStep === 0 && (
        <>
          <div className="cc_step_title">{data.steps[0]}</div>
          {message && (
            <div className="cc_info_filters_box">
              <p className={`cc_info_filters_${message.type}`}>
                {message.text}
              </p>
            </div>
          )}
          <Step1Indicators
            indicatorName={indicatorName}
            setIndicatorName={setIndicatorName}
            indicatorNameRef={indicatorNameRef}
            setIndicatorFormComplete={setIndicatorFormComplete}
            fileColumnSelectOptions={dataGetFileColumn}
            functionSelectOptions={dataGetindicatorFunctions}
            fileSelectedOptions={fileSelectedOptions}
            fileOptionsChangeHandler={fileOptionsChangeHandler}
            unitySelectedOptions={unitySelectedOptions}
            unityOptionsChangeHandler={unityOptionsChangeHandler}
            columnSelectedOptions={columnSelectedOptions}
            denominatorSelectedOptions={denominatorSelectedOptions}
            columnOptionsChangeHandler={columnOptionsChangeHandler}
            denominatorOptionsChangeHandler={denominatorOptionsChangeHandler}
            functionSelectedOptions={functionSelectedOptions}
            functionOptionsChangeHandler={functionOptionsChangeHandler}
            columnSelectRef={columnSelectRef}
            denominatorSelectRef={denominatorSelectRef}
            fileSelectRef={fileSelectRef}
            groupRef={groupRef}
            unityRef={unityRef}
            functionSelectRef={functionSelectRef} 
            activeIndicator={activeIndicator}
            setActiveIndicator={setActiveIndicator}
            groupSelectedOptions={groupSelectedOptions}
            groupChangeHandler={groupChangeHandler}
            groupSelectOptions={dataGetGroups}
            refetchGetGroups={refetchGetGroups}
            fileType="TEMPLATE"
          />
        </>
      )}
      {activeStep === 1 && (
        <>
          <div className="cc_step_title">{`${data.steps[1]}: ${fileSelectedOptions?.label}`}</div>
          <Step2
            fileColumnSelectOptions={dataGetFileColumn}
            fileSelectedOptions={fileSelectedOptions}
            columnFilterSelectedOptions={columnFilterSelectedOptions}
            setColumnFilterSelectedOptions={setColumnFilterSelectedOptions}
            columnFilterOptionsChangeHandler={columnFilterOptionsChangeHandler}
            conditionSelectedOptions={conditionSelectedOptions}
            setConditionSelectedOptions={setConditionSelectedOptions}
            conditionOptionsChangeHandler={conditionOptionsChangeHandler}
            valuesSelectedOptions={valuesSelectedOptions}
            setValuesSelectedOptions={setValuesSelectedOptions}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            filterTypeSelectedOptions={filterTypeSelectedOptions}
            setFilterTypeSelectedOptions={setFilterTypeSelectedOptions}
            filterTypeOptionsChangeHandler={filterTypeOptionsChangeHandler}
            filters={filters}
            setFilters={setFilters}
            external={false}
            width="90%"
            externalFileSelectedOptions={externalFileSelectedOptions}
          />
        </>
      )}
      {activeStep === 2 && (
        <>
          <div className="cc_step_title">{`${data.steps[2]}`}</div>
          <div className="cc_step4_container">
            <Step4
              fileColumnSelectOptions={dataGetFileColumn}
              fileSelectedOptions={fileSelectedOptions}
              externalFileSelectedOptions={externalFileSelectedOptions}
              setExternalFileSelectedOptions={setExternalFileSelectedOptions}
              externalFileOptionsChangeHandler={
                externalFileOptionsChangeHandler
              }
              externalColumnSelectedOptions={externalColumnSelectedOptions}
              setExternalColumnSelectedOptions={
                setExternalColumnSelectedOptions
              }
              externalColumnOptionsChangeHandler={
                externalColumnOptionsChangeHandler
              }
              columnFileSelectedOptions={columnFileSelectedOptions}
              setColumnFileSelectedOptions={setColumnFileSelectedOptions}
              columnFileOptionsChangeHandler={columnFileOptionsChangeHandler}
              filters={externalFilters}
              setFilters={setExternalFilters}
              setValidationsStep4Completed={setValidationsStep3Completed}
              externalFileSelectRef={externalFileSelectRef}
              externalColumnSelectRef={externalColumnSelectRef}
              columnFileSelectRef={columnFileSelectRef}
            />
            <Step2
              fileColumnSelectOptions={dataGetFileColumn}
              fileSelectedOptions={fileSelectedOptions}
              columnFilterSelectedOptions={columnFilterExternalSelectedOptions}
              setColumnFilterSelectedOptions={
                setColumnFilterExternalSelectedOptions
              }
              columnFilterOptionsChangeHandler={
                columnFilterExternalOptionsChangeHandler
              }
              conditionSelectedOptions={conditionExternalSelectedOptions}
              setConditionSelectedOptions={setConditionExternalSelectedOptions}
              conditionOptionsChangeHandler={
                conditionExternalOptionsChangeHandler
              }
              valuesSelectedOptions={valuesExternalSelectedOptions}
              setValuesSelectedOptions={setValuesExternalSelectedOptions}
              selectedValues={selectedExternalValues}
              setSelectedValues={setSelectedExternalValues}
              filterTypeSelectedOptions={filterTypeExternalSelectedOptions}
              setFilterTypeSelectedOptions={
                setFilterTypeExternalSelectedOptions
              }
              filterTypeOptionsChangeHandler={
                filterTypeExternalOptionsChangeHandler
              }
              filters={filtersExternal}
              setFilters={setFiltersExternal}
              external={true}
              width="60%"
              validationsStep4Completed={validationsStep3Completed}
              externalFileSelectedOptions={externalFileSelectedOptions}
            />
          </div>
        </>
      )}
      <div className="cc_steps_buttons">
        <Button
          title={t("indicatorsManagementTexts.configIndicators.former")}
          color="blue-greeny"
          type="button"
          onClick={handleBack}
          disabled={activeStep === 0}
        />
        <Tooltip title={t("indicatorsManagementTexts.configIndicators.resetAllIndicatorSteps")} arrow>
          <span>
            <Button
              title={t("indicatorsManagementTexts.configIndicators.cleanAll")}
              color="grey"
              type="button"
              onClick={handleRestart}
            />
          </span>
        </Tooltip>
        <Button
          title={t("indicatorsManagementTexts.configIndicators.finish")}
          color="blue"
          type="button"
          onClick={() => setOpenindicatorModal(true)}
          disabled={!indicatorFormComplete || message?.type === "error"}
        />
        <Button
          title={t("indicatorsManagementTexts.configIndicators.next")}
          color="blue-greeny"
          type="button"
          onClick={handleNext}
          disabled={activeStep === 2 || !indicatorFormComplete || message?.type === "error"}
        />
      </div>
    </ComponentLayout>
  );
};

export default ConfigIndicatorsFiles;
