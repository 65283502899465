import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { Stack } from "@mui/material";
import { useRef, useState } from "react";
import Button from "../../../buttons/Button";
import ColumnType from "./ColumnType";
import Dialog from "../../../dialog/Dialog";
import DropzoneInput from "../Shared/DropzoneInput";
import FileNameInput from "../Shared/FileNameInput";
import FormLayout from "../../../forms/FormLayout";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import MessageBlock from "../../../forms/MessageBlock";
import Select from "../../../forms/Select";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import TextAreaAutoSize from "../../../forms/TextAreaAutoSize";
import UploadedFile from "../Shared/UploadedFile";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import VirtualizatedTable from "../../../virtualizatedTable/VirtualizedTable";
import {
  COLUMN_FILE_URL,
  COMPANY_PARAMETERS_FILE_URL,
  PARAM_OTHER_FILE_URL,
} from "../../../../api/axios";
import { transformDataTypeColumns } from "./transformDataTypeColumns";

interface TypeModalContentProps {
  fileData: any;
  setFileData: any;
  handleClose: any;
  dataFilesList: any;
  refetchData?: any;
  type?: "GASTOS" | "VENTAS" | "OTRO";
}

const TypeModalContent = ({
  fileData,
  setFileData,
  handleClose,
  dataFilesList,
  refetchData,
  type
}: TypeModalContentProps) => {
  const { t } = useLanguage();
  const fileNameRef = useRef<HTMLInputElement>(null);
  const optionSelectRef: any = useRef(null);
  const [fileName, setfFileName] = useState(fileData?.nombre || "");
  const [description, setDescription] = useState(fileData?.descripcion || "");
  const [message, setMessage] = useState({
    type: "error",
    text: "",
  });
  const [expensesFileToUpload, setExpensesFileToUpload] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openColumnModal, toggleColumnModal] = useDialog();
  const [columnToModify, setColumnToModify] = useState<any>();  

  /************************************ Selects *********************************************************/
  const [
    optionSelectedOption,
    setOptionSelectedOption,
    columnOptionChangeHandler,
  ] = useSingleSelect(
    undefined,
    fileData?.id
      ? fileData?.muestra
        ? {
            value: "muestra",
            label: "Cargar una muestra",
          }
        : {
            value: "manual",
            label: "Agregar columnas manualmente",
          }
      : undefined
  );

  /************************************* Get datos del archivo ****************************************/

  const onSuccessGetFilesParams = (data) => {
    setFileData(data);
  };

  const {
    isLoading: isLoadingFilesParams,
    callApi: getFilesParams,
    data: dataGetFilesParams,
  } = useApi(
    COMPANY_PARAMETERS_FILE_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    onSuccessGetFilesParams,
    undefined,
    false
  );

  /************************************ Posteo inicial de nombre y descripción **************************/

  const onApplyClick = () => {
    const data = {
      id: fileData?.id,
      nombre: fileName,
      descripcion: description,
      tipo: type ? type : "OTRO",
    };
    if (fileData?.id) {
      putFileParams(undefined, data);
    } else {
      postFileParams(undefined, data);
    }
  };

  const onSuccessPost = (data) => {
    setMessage({ type: "success", text: "Archivo actualizado correctamente." });
    setTimeout(() => {
      setMessage({ type: "success", text: "" });
    }, 5000);
    getFilesParams(`${COMPANY_PARAMETERS_FILE_URL}/${data?.id}`);
  };


  const onSuccessPut = (data) => {
    setMessage({ type: "success", text: "Archivo actualizado correctamente." });
    setTimeout(() => {
      setMessage({ type: "success", text: "" });
    }, 5000);
    getFilesParams(`${COMPANY_PARAMETERS_FILE_URL}/${fileData?.id}`);
  };

  const { isLoading: isLoadingPostFileParams, callApi: postFileParams } =
    useApi(
      COMPANY_PARAMETERS_FILE_URL,
      "POST",
      t("companyParam.postParam.codes"),
      undefined,
      onSuccessPost,
      undefined,
      true
    );

  const { isLoading: isLoadingPutFileParams, callApi: putFileParams } = useApi(
    `${COMPANY_PARAMETERS_FILE_URL}/${fileData?.id}`,
    "PUT",
    t("companyParam.postParam.codes"),
    undefined,
    onSuccessPut,
    undefined,
    true
  );

  /************************************* Posteo de muestra de archivo *********************************/

  const onSuccessPostFile = () => {
    setExpensesFileToUpload([]);
    getFilesParams(`${COMPANY_PARAMETERS_FILE_URL}/${fileData.id}`);
  };

  const { isLoading: isLoadingPostFile, callApi: postFile } = useApi(
    PARAM_OTHER_FILE_URL,
    "POST",
    t("companyParam.postParamFile.codes"),
    undefined,
    onSuccessPostFile,
    undefined,
    true
  );

  /************************************** Nueva/Modificar columna **********************************************/

  const handleNewColumn = () => {
    setColumnToModify(undefined);
    toggleColumnModal();
  };

  const handleModifyColumn = () => {
    let updateColumn: any[] = [];
    if (selectedRows && selectedRows.length == 1) {
      updateColumn = fileData.columnas.find((column: any) => {
        return column.id === selectedRows[0];
      });
      setColumnToModify({ ...updateColumn, id: selectedRows });
    } else if (selectedRows && selectedRows.length > 1) {
      setColumnToModify({ id: selectedRows });
    }
    toggleColumnModal();
  };

  /****************************************** Delete column ****************************************/

  const onSuccessDeleteColumn = () => {
    getFilesParams(`${COMPANY_PARAMETERS_FILE_URL}/${fileData.id}`);
  };

  const {
    isLoading: isLoadingDeleteSingleColumn,
    callApi: deleteSingleColumn,
  } = useApi(
    undefined,
    "DELETE",
    t("companyParam.postParamFile.codes"),
    undefined,
    onSuccessDeleteColumn,
    undefined,
    true
  );

  const { isLoading: isLoadingDeleteMultiColumn, callApi: deleteMultiColumn } =
    useApi(
      COLUMN_FILE_URL(fileData?.id),
      "DELETE",
      t("companyParam.postParamFile.codes"),
      undefined,
      onSuccessDeleteColumn,
      undefined,
      true
    );

  const handleDeleteColumn = () => {
    if (selectedRows && selectedRows.length > 1) {
      deleteMultiColumn(COLUMN_FILE_URL(fileData?.id), { id: selectedRows });
    } else if (selectedRows && selectedRows.length == 1) {
      deleteSingleColumn(`${COLUMN_FILE_URL(fileData?.id)}/${selectedRows[0]}`);
    }
  };

  /*************************************** Cierre ***************************************/

  const handleCloseModal = () => {
    refetchData();
    handleClose();
  }

  console.log(expensesFileToUpload, "expensesFileToUpload");
  

  return (
    <Stack width={"100%"}>
      <SimpleBackdrop
        open={
          isLoadingPostFileParams ||
          isLoadingPutFileParams ||
          isLoadingFilesParams ||
          isLoadingPostFile ||
          isLoadingDeleteMultiColumn ||
          isLoadingDeleteSingleColumn
        }
        message={t("general.loading")}
      />
      <FormLayout width="100%">
        <MessageBlock message={message} />
        <FileNameInput
          fileNameRef={fileNameRef}
          fileName={fileName}
          setfileName={setfFileName}
          setMessage={setMessage}
          dataFilesList={dataFilesList}
        />
        <FormSelectLayout title={"Descripción"}>
          <TextAreaAutoSize
            placeholder="Descripción del archivo..."
            value={description}
            setValue={setDescription}
          />
        </FormSelectLayout>
        <Stack
          sx={{ width: "100%", flexDirection: "row", justifyContent: "center" }}
        >
          <Button
            title={t("general.apply")}
            color="blue-greeny"
            type="button"
            onClick={onApplyClick}
            disabled={!fileName}
          />
        </Stack>
      </FormLayout>
      {fileData?.id && (
        <FormLayout width="100%">
          <FormSelectLayout title={"¿Qué desea hacer?"} required={true}>
            <Select
              reference={optionSelectRef}
              styles={selectStyles(optionSelectedOption)}
              options={[
                {
                  value: "muestra",
                  label: "Cargar una muestra",
                },
                {
                  value: "manual",
                  label: "Agregar columnas manualmente",
                },
              ]}
              className="cc_select"
              onChange={columnOptionChangeHandler}
              closeMenuOnSelect
              placeholder={t("general.selectOption")}
              defaultValue={optionSelectedOption}
              isClearable
            />
          </FormSelectLayout>
          {optionSelectedOption?.value === "muestra" && (
            <DropzoneInput
              fileToUpload={expensesFileToUpload}
              setFileToUpload={setExpensesFileToUpload}
              postFile={postFile}
              url={`${PARAM_OTHER_FILE_URL(fileData.id)}`}
              required={false}
            />
          )}
          {optionSelectedOption?.value === "muestra" && fileData?.muestra && (
            <UploadedFile
              dataGetParams={{
                archivo: {
                  name: fileData?.muestra?.name,
                  type: fileData?.muestra?.type,
                },
              }}
            />
          )}
          {((optionSelectedOption?.value === "muestra" && fileData?.muestra) ||
            optionSelectedOption?.value === "manual") && (
            <Stack
              sx={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                title={t("Agregar columna")}
                color="blue"
                type="button"
                onClick={handleNewColumn}
                disabled={!fileName}
              />
              <Button
                title={t("Modificar columna")}
                color="outlined"
                type="button"
                onClick={handleModifyColumn}
                disabled={
                  !fileName || !selectedRows || selectedRows.length === 0
                }
              />
              <Button
                title={t("Eliminar columna")}
                color="magenta"
                type="button"
                onClick={handleDeleteColumn}
                disabled={
                  !fileName || !selectedRows || selectedRows.length === 0
                }
              />
            </Stack>
          )}
          {((optionSelectedOption?.value === "muestra" && fileData?.muestra) ||
            optionSelectedOption?.value === "manual") && (
            <Stack sx={{ width: "100%" }}>
              {fileData?.columnas?.length > 0 && (
                <VirtualizatedTable
                  columns={
                    transformDataTypeColumns(t, fileData?.columnas).columns
                  }
                  rows={transformDataTypeColumns(t, fileData?.columnas).rows}
                  rowsPerPageOptions={[5, 10, 20]}
                  rowHeight={35}
                  tableHeight={"300px"}
                  hideFooter={true}
                  toolbar={true}
                  columnsButton={true}
                  filterButton={true}
                  densitySelector={true}
                  exportButton={false}
                  saveViewButton={false}
                  setSelectedRows={setSelectedRows}
                  selectedRows={selectedRows}
                />
              )}
            </Stack>
          )}
        </FormLayout>
      )}
      <Stack
        sx={{ width: "100%", flexDirection: "row", justifyContent: "center" }}
      >
        <Button
          title={t("general.close")}
          color="grey"
          type="button"
          onClick={handleCloseModal}
          disabled={!fileName}
        />
      </Stack>
      <Dialog
        open={openColumnModal}
        handleClose={toggleColumnModal}
        title={t("Nueva columna")}
        maxWidth="xl"
      >
        <Stack width={"1000px"} minHeight={"200px"}>
          <ColumnType
            column={columnToModify}
            file={fileData}
            handleClose={toggleColumnModal}
            getFilesParams={getFilesParams}
            setSelectedRows={setSelectedRows}
          />
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default TypeModalContent;
