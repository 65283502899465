import { back_arrow_styles } from "../../styles/app-styles";
import { useCompanyParamProvider } from "../../context/CompanyParamProvider";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ComponentLayout from "../layout/ComponentLayout";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Step0 from "./StepperView/Step0/Step0";
import Step1 from "./StepperView/Step1/Step1";
import Step2 from "./StepperView/Step2/Step2";
import Step3 from "./StepperView/Step3/Step3";
import Step4 from "./StepperView/Step4/Step4";
import StepperView from "./StepperView/StepperView";
import SummaryView from "./SummaryView/SummaryView";
import useLanguage from "../../context/LanguageProvider";

const CompanyParameterization = () => {
  const { t } = useLanguage();
  const location = useLocation();
  const { activeStep, setActiveStep, stepToEdit, setStepToEdit } =
    useCompanyParamProvider();
  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    if(location.state && location.state.step){
      setActiveStep(location.state.step);
      setStepToEdit(location.state.step);
      setIsEditOpen(true);
    } else if (stepToEdit) {
      setActiveStep(stepToEdit);
    }
  }, [stepToEdit, location]);

    return (
    <ComponentLayout
      title={t("menuItemsOptions.companyParameterization.title")}
      icon={
        isEditOpen ? (
          <ArrowBackIcon
            sx={back_arrow_styles}
            onClick={() => setIsEditOpen(false)}
          />
        ) : (
          <AssignmentTurnedInIcon />
        )
      }
    >
      <Grid2 container direction={"column"} md={12} mt={1}>
        {isEditOpen ? (
          <StepperView setIsEditOpen={setIsEditOpen}>
            {activeStep === 0 && <Step0 />}
            {activeStep === 1 && <Step1 />}
            {activeStep === 2 && <Step2 />}
            {activeStep === 3 && <Step3 />}
            {activeStep === 4 && <Step4 />}          
          </StepperView>
        ) : (
          <SummaryView setIsEditOpen={setIsEditOpen} />
        )}
      </Grid2>
    </ComponentLayout>
  );
};

export default CompanyParameterization;
