import * as React from "react";
import { getUserCompany } from "../company/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import logoPhi from "../../assets/logoPhiWhiteCompleted.png";
import Modal from "@mui/material/Modal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  companySelectorStyle,
  Drawer,
  DrawerHeader,
  menu_logo,
  menu_logo_container_header,
} from "../../styles/app-styles";
import {
  dashboardCollapseList,
  menuItemsOptions,
  parameterModelCollapseList,
  dataExportModellingCollapseList,
  simulatorCollapseList,
  validationsCollapseList,
  returnItemRole,
  marketBasketCollapseList,
  pnLParameterizationCollapseList,
  criteriasCollapseList,
} from "./utils";
import AppBarComponent from "./AppBarComponent";
import ListItemComponent from "./ListItemComponent";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import CompanySelector from "../company/CompanySelector";
import ChatBotMenuItem from "../chatBot/ChatBotMenuItem";
import { Stack } from "@mui/material";
import ChatBotButton from "../chatBot/ChatBotButton";

/*  Para agregar un nuevo item al menú de opciones, primero se debe definir si es un item
 del menú principal o item de un submenú (subitem).
 1) Para los items, se debe agregar un nuevo objeto  en el arreglo menuItemsOptions (utils), el cual debe tener la siguiente estructura:
    {
      title: "Nombre del item",
      url: "url del item",
      icon: Icono del item
    } 
 2) Luego actualizar en isOptionSelected (utils) para que devuelva true si el item es seleccionado (esta función maneja los estilos).
 3) Cuando un item tiene subitems, se debe agregar en openMenuList (estado de este componente) una nueva clave con el nombre del item y el valor inicial en false, y actualizar la funciones handleClickOpenMenu, handleOpenStates y handleCollapseList con esos items, ya que estas funciones que manejan los estados de apertura y cierre.
 4) Para los subitems se debe crear el objeto del submenú correspondiente (submenuCollapseList) en utils, el cual debe tener la siguiente estructura:
  {
    title: "Nombre del subitem",
    url: "url del subitem",
    icon: Icono del subitem
  }    

5) ROLES
  Para dar acceso a una vista (item del menú) a un rol de usuario, se debe actualizar la función returnItemRole (utils del menú) con ese item según la estructura acordada con el back.
  Además agregar la nueva opción dentro del archivo src/components/administrator/data.ts, constantes emptyRolConfig y RolConfig
  y en el archivo src/components/auth/utils.ts constante returnItemRole.
  A los navigate, agregarle la "/" adelante de la url para que funcione el redireccionamiento.
  Al dar permisos con userRolData, validar primero con "userRolData &&" para que no tire error si no se carga el usuario.
 */

export default function MiniDrawer() {
  const theme = useTheme();
  const { userRolData }: any = useInfoContext();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [openBasicModal, setOpenBasicModal] = useState(false);
  const handleOpenBasicModal = () => setOpenBasicModal(true);
  const handleCloseBasicModal = () => setOpenBasicModal(false);
  const [dataGetUserCompany, setDataGetUserCompany] = useState<any>(null);
  const [openMenuList, setOpenMenuList] = useState({
    openParameterModel: false,
    openPnLParameterization: false,
    openParameterization: false,
    openDashboard: false,
    openDataExportModelling: false,
    openSimulator: false,
    openValidations: false,
    openMarketBasket: false,
    openCriterias: false,
  });
  const { setCompany, setCompanyData }: any = useInfoContext();
  const { t } = useLanguage();

  const backgroundColor = "var(--bg-main)";

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const goHome = () => {
    navigate("/inicio");
    setOpen(false);
  };

  const handleGetCompanyResponse = (status: number, data: any) => {
    if (status === 200) {
      setDataGetUserCompany(data);
      setCompany({ value: data.id, label: data.nombre_visual });
      setCompanyData(data);
    }
  };

  useEffect(() => {
    getUserCompany(axiosPrivate, handleGetCompanyResponse);
  }, []);

  const handleOpenStates = (listOption: string) => {
    switch (listOption) {
      case t("menuItemsOptions.modelParameterization.title"):
        return openMenuList.openParameterModel;
      case t("menuItemsOptions.pnLParameterization.title"):
        return openMenuList.openPnLParameterization;
      case "Dashboard":
        return openMenuList.openDashboard;
      case "Data Export Modelling":
        return openMenuList.openDataExportModelling;
      case t("menuItemsOptions.simulator.title"):
        return openMenuList.openSimulator;
      case t("menuItemsOptions.validations.title"):
        return openMenuList.openValidations;
      case t("menuItemsOptions.marketBasketCollapseList.title"):
        return openMenuList.openMarketBasket;
      case t("menuItemsOptions.criteriaManagement.title"):
        return openMenuList.openCriterias;
      default:
        return false;
    }
  };

  const handleCollapseList = (listOption: string) => {
    switch (listOption) {
      case t("menuItemsOptions.modelParameterization.title"):
        return parameterModelCollapseList(t);
      case t("menuItemsOptions.pnLParameterization.title"):
        return pnLParameterizationCollapseList(t);
      case "Dashboard":
        return dashboardCollapseList(t);
      case "Data Export Modelling":
        return dataExportModellingCollapseList(t);
      case t("menuItemsOptions.simulator.title"):
        return simulatorCollapseList(t);
      case t("menuItemsOptions.validations.title"):
        return validationsCollapseList(t);
      case t("menuItemsOptions.marketBasketCollapseList.title"):
        return marketBasketCollapseList(t);
      case t("menuItemsOptions.criteriaManagement.title"):
        return criteriasCollapseList(t);

      default:
        return [];
    }
  };

  const handleClickOpenMenu = (listOption: string) => {
    switch (listOption) {
      case t("menuItemsOptions.pnLParameterization.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openPnLParameterization: !prev.openPnLParameterization,
        }));
        break;
      case t("menuItemsOptions.modelParameterization.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openParameterModel: !prev.openParameterModel,
        }));
        break;
      case t("menuItemsOptions.validations.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openValidations: !prev.openValidations,
        }));
        break;
      case "Dashboard":
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openDashboard: !prev.openDashboard,
        }));
        break;
      case "Data Export Modelling":
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openDataExportModelling: !prev.openDataExportModelling,
        }));
        break;
      case t("menuItemsOptions.simulator.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openSimulator: !prev.openSimulator,
        }));
        break;
      case t("menuItemsOptions.marketBasketCollapseList.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openMarketBasket: !prev.openMarketBasket,
        }));
        break;
      case t("menuItemsOptions.criteriaManagement.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openCriterias: !prev.openCriterias,
        }));
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarComponent
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        goHome={goHome}
        handleOpenBasicModal={handleOpenBasicModal}
      />
      <Drawer
        variant="permanent"
        open={open}
        onMouseOver={handleDrawerOpen} // Sacando esto se saca el open en el hover
        onMouseOut={handleDrawerClose} // Sacando esto se saca el open en el hover
        sx={{
          background: backgroundColor,
          "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-12i7wg6-MuiPaper-root-MuiDrawer-paper":
            { backgroundColor: backgroundColor }, // Sección abajo del menú
          "& .MuiPaper-root.MuiDrawer-paperAnchorLeft": {
            backgroundColor: backgroundColor,
          }, // Sección abajo del menú
        }}
      >
        <DrawerHeader
          sx={{
            background: backgroundColor,
            "&.MuiPaper-root": { backgroundColor: backgroundColor },
          }}
        >
          <Box
            sx={{ ...menu_logo_container_header, cursor: "pointer" }}
            onClick={goHome}
          >
            <img style={menu_logo} src={logoPhi} alt="logo" />
          </Box>
          <IconButton onClick={handleDrawer}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <List sx={{ backgroundColor: backgroundColor }}>
            {menuItemsOptions(t).map((item, index) => {
              //Visualización de pantallas según rol de usuario
              return (
                returnItemRole(item, userRolData, t) && (
                  <ListItemComponent
                    key={index}
                    item={item}
                    navigate={navigate}
                    open={open}
                    handleClickOpenMenu={handleClickOpenMenu}
                    handleOpenStates={handleOpenStates}
                    handleCollapseList={handleCollapseList}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                  />
                )
              );
            })}
          </List>
          {(userRolData?.chatbot?.visualizacion ||
            userRolData?.chatbot?.visualizacion_y_creacion ||
            userRolData?.chatbot?.visualizacion_y_edicion) && (
            <ChatBotMenuItem
              open={open}
              handleDrawerClose={handleDrawerClose}
            />
          )}
        </Stack>
      </Drawer>
      <Box>
        <Modal
          open={openBasicModal}
          onClose={handleCloseBasicModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={companySelectorStyle}>
            <CompanySelector
              menu={true}
              handleCloseBasicModal={handleCloseBasicModal}
            />
          </Box>
        </Modal>
      </Box>
      {/* <ChatBotButton /> */}
    </Box>
  );
}
