import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { GENERATED_RESOURCES, GET_DOWNLOAD_ZIP_FILE } from "../../api/axios";
import { TaskType } from "./types";
import { transformText } from "../criterias/utils";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

export const sortTasksByDate = (tasks: TaskType[]) => {
  const sortedTasks = tasks.slice().sort((a: TaskType, b: TaskType) => {
    return new Date(b.fecha).getTime() - new Date(a.fecha).getTime();
  });
  return sortedTasks;
};

export const convertArrayToText = (array: string[]) => {
  let text = "";
  array.forEach((item, index) => {
    text += item + (index !== array.length - 1 ? ", " : "");
  });
  return text;
};

export const convertArrayToTextWithoutComas = (array: string[]) => {
  let text = "";
  array.forEach((item, index) => {
    text += item + " ";
  });
  return text;
};

export const formatDate = (utcDateString) => {
  const newDate = new Date(utcDateString);
  const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
  const formattedDate = newDate.toLocaleDateString(resolvedOptions.locale || 'es-AR', {
    //TODO: hacer dinámico el locale
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: resolvedOptions.timeZone,
  });
  return formattedDate;
};
interface StateCellProps {
  startIcon: JSX.Element;
  text: string;
}

export const transformColumnDate = (rows: any[]) => {
  return rows?.map((row: any) => {
    row.fecha = formatDate(row.fecha);
    return row;
  });
};

export const StateCell = ({ startIcon, text }: StateCellProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {startIcon}
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        {text}
      </Typography>
    </Stack>
  );
};

export const renderTaskCell = (
  t: any,
  tabValue: string,
  column: string,
  params: any,
  buttonsActions: any
) => {
  if (
    tabValue === t("tabs.preproration") ||
    tabValue === t("tabs.proration") ||
    tabValue === t("tabs.datamodel") ||
    tabValue === t("tabs.salesForecast")
  ) {
    switch (column) {
      case t("columns.state"):
        switch (params.value.toLowerCase()) {
          case "pendiente":
            return (
              <StateCell
                startIcon={<CircularProgress size={20} color="secondary" />}
                text={t("taskManager.states.pending")}
              />
            );
          case "corriendo":
            return (
              <StateCell
                startIcon={<CircularProgress size={20} color="secondary" />}
                text={t("taskManager.states.running")}
              />
            );
          case "finalizada":
            return params.row.posee_errores ? (
              <StateCell
                startIcon={
                  <ErrorOutlineIcon sx={{ color: "var(--icon-error)" }} />
                }
                text={t("taskManager.states.finishedWithErrors")}
              />
            ) : (
              <StateCell
                startIcon={<DoneIcon sx={{ color: "var(--icon-success)" }} />}
                text={t("taskManager.states.finishedSuccessfully")}
              />
            );
          case "error":
            return (
              <StateCell
                startIcon={
                  <ErrorOutlineIcon sx={{ color: "var(--icon-error)" }} />
                }
                text={t("taskManager.states.error")}
              />
            );
          case "cancelada":
            return (
              <StateCell
                startIcon={<CloseIcon sx={{ color: "var(--icon-error)" }} />}
                text={t("taskManager.states.canceled")}
              />
            );
          default:
            return (
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {params.value}
              </Typography>
            );
        }
      case t("columns.action"):
        switch (params.row.estado.toLowerCase()) {
          case "pendiente":
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  startIcon={<ZoomInIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-success)" } }}
                  onClick={() =>
                    buttonsActions.handleGetTaskDetail(params.row.id)
                  }
                >
                  {t("taskManager.actions.viewDetail")}
                </Button>
                <Button
                  startIcon={<CloseIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-error)" } }}
                  onClick={() => buttonsActions.saveDeleteTask(params.row.id)}
                >
                  {t("taskManager.actions.cancel")}
                </Button>
              </Stack>
            );
          case "corriendo":
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  startIcon={<ZoomInIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-success)" } }}
                  onClick={() =>
                    buttonsActions.handleGetTaskDetail(params.row.id)
                  }
                >
                  {t("taskManager.actions.viewDetail")}
                </Button>
                <Button
                  startIcon={<CloseIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-error)" } }}
                  onClick={() => buttonsActions.saveDeleteTask(params.row.id)}
                >
                  {t("taskManager.actions.cancel")}
                </Button>
              </Stack>
            );
          case "finalizada":
            return (
              <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-success)" } }}
                onClick={() =>
                  buttonsActions.handleGetTaskDetail(params.row.id)
                }
              >
                {t("taskManager.actions.viewDetail")}
              </Button>
            );
          case "cancelada":
            return (
              <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-success)" } }}
                onClick={() =>
                  buttonsActions.handleGetTaskDetail(params.row.id)
                }
              >
                {t("taskManager.actions.viewDetail")}
              </Button>
            );
          case "error":
            return (
              <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-success)" } }}
                onClick={() =>
                  buttonsActions.handleGetTaskDetail(params.row.id)
                }
              >
                {t("taskManager.actions.viewDetail")}
              </Button>
            );
          default:
            return params.value;
        }
      case t("columns.period"):
        return (
          <Typography variant="body2" sx={{ ml: 1 }}>
            {transformText(convertArrayToText(params.value), 35, t)}
          </Typography>
        );
      case t("columns.date"):
        return (
          <Typography variant="body2" sx={{ ml: 1 }}>
            {formatDate(params.value)}
          </Typography>
        );
      case t("columns.referenceName"):
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.nombre_referencia}
          </Typography>
        );
      case t('columns.analysis'):
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.analisis}
          </Typography>
        );
      default:
        return params.value;
    }
  }
  if (tabValue === t("tabs.downloads")) {
    switch (column.toLowerCase()) {
      case t("columns.file"):
        return (
          <Tooltip
            title={transformText(
              convertArrayToText(params.row.archivo),
              2000,
              t
            )}
            sx={{ verticalAlign: "center", fontSize: "16px" }}
          >
            <Typography sx={{ fontSize: "14px" }}>
              {params.row.nombre_archivo}
            </Typography>
          </Tooltip>
        );
      case t("columns.period"):
        return (
          <Typography variant="body2" sx={{ ml: 1 }}>
            {transformText(convertArrayToText(params.value), 35, t)}
          </Typography>
        );
      case t("columns.state"):
        switch (params.value.toLowerCase()) {
          case "pendiente":
            return (
              <StateCell
                startIcon={<CircularProgress size={20} color="secondary" />}
                text={t("taskManager.states.pending")}
              />
            );
          case "finalizada":
            return (
              <StateCell
                startIcon={<DoneIcon sx={{ color: "var(--icon-success)" }} />}
                text={t("taskManager.states.finishedSuccessfully")}
              />
            );
          case "error":
            return (
              <StateCell
                startIcon={
                  <ErrorOutlineIcon sx={{ color: "var(--icon-error)" }} />
                }
                text={t("taskManager.states.downloadError")}
              />
            );
          default:
            return params.value;
        }
      case t("columns.action"):
        switch (params.row.estado.toLowerCase()) {
          case "pendiente":
            return (
              <Button
                startIcon={<CloseIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-error)" } }}
                onClick={() => buttonsActions.saveDeleteTask(params.row.id)}
              >
                {t("taskManager.actions.cancel")}
              </Button>
            );
          case "finalizada":
            return (
              <Button
                startIcon={<DownloadIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-secondary)" } }}
                onClick={() =>
                  buttonsActions.downloadFile(
                    GET_DOWNLOAD_ZIP_FILE(params.row.id),
                    undefined,
                    {
                      fileName: `${params.row.nombre_archivo}`,
                      fileType: "aplication/zip",
                    }
                  )
                }
              >
                {t("taskManager.actions.download")}
              </Button>
            );
          default:
            return params.value;
        }
      default:
        return params.value;
    }
  }
  if (tabValue === "ANALISIS_DATAEXPORT" || tabValue === "ARCHIVOS") {
    switch (column.toLowerCase()) {
      case t("columns.file"):
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.nombre_archivo}
          </Typography>
        );
      case t("columns.date"):
        return (
          <Typography variant="body2" sx={{ ml: 1 }}>
            {formatDate(params.value)}
          </Typography>
        );
      case t("columns.action"):
        return (
          <Button
            startIcon={<DownloadIcon />}
            variant="text"
            sx={{ ":hover": { color: "var(--text-secondary)" } }}
            onClick={() =>
              buttonsActions.downloadFile(
                `${GENERATED_RESOURCES}/${params.row.id}`,
                undefined,
                {
                  fileName:
                    tabValue === "ANALISIS_DATAEXPORT"
                      ? `${params.row.nombre_archivo}.zip`
                      : `${params.row.nombre_archivo}`,
                  fileType: "aplication/zip",
                }
              )
            }
          >
            {t("taskManager.actions.download")}
          </Button>
        );
      default:
        return params.value;
    }
  }
  if (tabValue === t("tabs.simulation")) {
    switch (column) {
      case t("columns.state"):
        switch (params.value.toLowerCase()) {
          case "pendiente":
            return (
              <StateCell
                startIcon={<CircularProgress size={20} color="secondary" />}
                text={t("taskManager.states.pending")}
              />
            );
          case "corriendo":
            return (
              <StateCell
                startIcon={<CircularProgress size={20} color="secondary" />}
                text={t("taskManager.states.running")}
              />
            );
          case "finalizada":
            return params.row.posee_errores ? (
              <StateCell
                startIcon={
                  <ErrorOutlineIcon sx={{ color: "var(--icon-error)" }} />
                }
                text={t("taskManager.states.finishedWithErrors")}
              />
            ) : (
              <StateCell
                startIcon={<DoneIcon sx={{ color: "var(--icon-success)" }} />}
                text={t("taskManager.states.finishedSuccessfully")}
              />
            );
          case "error":
            return (
              <StateCell
                startIcon={
                  <ErrorOutlineIcon sx={{ color: "var(--icon-error)" }} />
                }
                text={t("taskManager.states.error")}
              />
            );
          case "cancelada":
            return (
              <StateCell
                startIcon={<CloseIcon sx={{ color: "var(--icon-error)" }} />}
                text={t("taskManager.states.canceled")}
              />
            );
          default:
            return (
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {params.value}
              </Typography>
            );
        }
      case t("columns.action"):
        switch (params.row.estado.toLowerCase()) {
          case "pendiente":
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  startIcon={<ZoomInIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-success)" } }}
                  onClick={() =>
                    buttonsActions.handleGetTaskDetail(params.row.id)
                  }
                >
                  {t("taskManager.actions.viewDetail")}
                </Button>
                <Button
                  startIcon={<CloseIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-error)" } }}
                  onClick={() => buttonsActions.saveDeleteTask(params.row.id)}
                >
                  {t("taskManager.actions.cancel")}
                </Button>
              </Stack>
            );
          case "corriendo":
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  startIcon={<ZoomInIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-success)" } }}
                  onClick={() =>
                    buttonsActions.handleGetTaskDetail(params.row.id)
                  }
                >
                  {t("taskManager.actions.viewDetail")}
                </Button>
                <Button
                  startIcon={<CloseIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-error)" } }}
                  onClick={() => buttonsActions.saveDeleteTask(params.row.id)}
                >
                  {t("taskManager.actions.cancel")}
                </Button>
              </Stack>
            );
          case "finalizada":
            return (
              <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-success)" } }}
                onClick={() =>
                  buttonsActions.handleGetTaskDetail(params.row.id)
                }
              >
                {t("taskManager.actions.viewDetail")}
              </Button>
            );
          case "cancelada":
            return (
              <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-success)" } }}
                onClick={() =>
                  buttonsActions.handleGetTaskDetail(params.row.id)
                }
              >
                {t("taskManager.actions.viewDetail")}
              </Button>
            );
          case "error":
            return (
              <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-success)" } }}
                onClick={() =>
                  buttonsActions.handleGetTaskDetail(params.row.id)
                }
              >
                {t("taskManager.actions.viewDetail")}
              </Button>
            );
          default:
            return params.value;
        }
      case t("columns.date"):
        return (
          <Typography variant="body2" sx={{ ml: 1 }}>
            {formatDate(params.value)}
          </Typography>
        );
      case t("columns.referenceName"):
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.nombre_referencia}
          </Typography>
        );
      default:
        return params.value;
    }
  }
  if (tabValue === t('tabs.marketBasket')) {
    switch (column.toLowerCase()) {
      case t("columns.referenceName"):
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.nombre_referencia}
          </Typography>
        );
      case t("columns.date"):
        return (
          <Typography variant="body2" sx={{ ml: 1 }}>
            {formatDate(params.value)}
          </Typography>
        );
      case t("columns.lastPeriod"):
        return (
          <Typography variant="body2" sx={{ ml: 1 }}>
            {params.value}
          </Typography>
        );
      case t("columns.state"):
        switch (params.value.toLowerCase()) {
          case "pendiente":
            return (
              <StateCell
                startIcon={<CircularProgress size={20} color="secondary" />}
                text={t("taskManager.states.pending")}
              />
            );
          case "corriendo":
            return (
              <StateCell
                startIcon={<CircularProgress size={20} color="secondary" />}
                text={t("taskManager.states.running")}
              />
            );
          case "finalizada":
            return params.row.posee_errores ? (
              <StateCell
                startIcon={
                  <ErrorOutlineIcon sx={{ color: "var(--icon-error)" }} />
                }
                text={t("taskManager.states.finishedWithErrors")}
              />
            ) : (
              <StateCell
                startIcon={<DoneIcon sx={{ color: "var(--icon-success)" }} />}
                text={t("taskManager.states.finishedSuccessfully")}
              />
            );
          case "error":
            return (
              <StateCell
                startIcon={
                  <ErrorOutlineIcon sx={{ color: "var(--icon-error)" }} />
                }
                text={t("taskManager.states.error")}
              />
            );
          case "cancelada":
            return (
              <StateCell
                startIcon={<CloseIcon sx={{ color: "var(--icon-error)" }} />}
                text={t("taskManager.states.canceled")}
              />
            );
          default:
            return (
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {params.value}
              </Typography>
            );
        }
      case t("columns.action"):
        switch (params.row.estado.toLowerCase()) {
          case "pendiente":
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                {/* <Button
                  startIcon={<ZoomInIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-success)" } }}
                  onClick={() =>
                    buttonsActions.handleGetTaskDetail(params.row.id)
                  }
                >
                  {t("taskManager.actions.viewDetail")}
                </Button> */}
                <Button
                  startIcon={<CloseIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-error)" } }}
                  onClick={() => buttonsActions.saveDeleteTask(params.row.id)}
                >
                  {t("taskManager.actions.cancel")}
                </Button>
              </Stack>
            );
          case "corriendo":
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                {/* <Button
                  startIcon={<ZoomInIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-success" } }}
                  onClick={() =>
                    buttonsActions.handleGetTaskDetail(params.row.id)
                  }
                >
                  {t("taskManager.actions.viewDetail")}
                </Button> */}
                <Button
                  startIcon={<CloseIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--text-error)" } }}
                  onClick={() => buttonsActions.saveDeleteTask(params.row.id)}
                >
                  {t("taskManager.actions.cancel")}
                </Button>
              </Stack>
            );
          case "finalizada":
            return (<>
              {/* <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-success)" } }}
                onClick={() =>
                  buttonsActions.handleGetTaskDetail(params.row.id)
                }
              >
                {t("taskManager.actions.viewDetail")}
              </Button> */}
            </>

            );
          case "cancelada":
            return (<>{/* <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-success)" } }}
                onClick={() =>
                  buttonsActions.handleGetTaskDetail(params.row.id)
                }
              >
                {t("taskManager.actions.viewDetail")}
              </Button> */}
            </>

            );
          case "error":
            return (
              <>{/* <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--text-success)" } }}
                onClick={() =>
                  buttonsActions.handleGetTaskDetail(params.row.id)
                }
              >
                {t("taskManager.actions.viewDetail")}
              </Button> */}</>

            );
          default:
            return params.value;
        }
      default:
        return params.value;
    }
  }

};
