import "./styles.css";
import { ActionMeta } from "react-select";
import { Button as CustomButton } from "../buttons";
import { CustomSelect } from "../forms";
import { Transformation } from "./types";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import CreatableSelect from "react-select/creatable";
import DeleteForever from "../icons/DeleteForever";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import useLanguage from "../../context/LanguageProvider";
interface IModalContent {
  columnSelectRef: any;
  valueSelectRef: any;
  columnSelected: string;
  setColumnSelected: (value: string) => void;
  valueSelected: string;
  newValueError: string;
  setNewValueError: (value: string) => void;
  setValueSelected: (value: string) => void;
  selectedRows: any[];
  handleToggleTransformModal: () => void;
  handleToggleConfirmationModal: () => void;
  optionsColumns: any;
  transformedValues: any;
  setTransformedValues: any;
  rows: any;
}

const ModalContent = ({
  columnSelectRef,
  valueSelectRef,
  columnSelected,
  setColumnSelected,
  valueSelected,
  setValueSelected,
  newValueError,
  setNewValueError,
  selectedRows,
  handleToggleTransformModal,
  handleToggleConfirmationModal,
  optionsColumns,
  transformedValues,
  setTransformedValues,
  rows,
}: IModalContent) => {
  const { t } = useLanguage();
  //Una vez seleccionada una columna dentro del modal de Modificar, devuelve todos los valores para esa columna
  const totalValues = rows.map((row: any) => {
    const columnValue =
      Object.keys(row).find((key) => key === columnSelected) || "";
    return row[columnValue];
  });

  //Elimina los elementos repetidos de los valores de la columna seleccionada
  const valuesForSelect = totalValues.filter((item: any, index: any) => {
    return totalValues.indexOf(item) === index;
  });

  //Devuelve los valores para el Select del Modal Modificar
  const optionsValues: any = valuesForSelect.map((value: any) => {
    return {
      value: value,
      label: value,
    };
  });

  //Elimina un par de columna y valor del array de transformaciones
  const handleDeleteTransform = (column: string, newValue: string) => {
    const newTransformedValues = transformedValues.filter(
      (item: Transformation) =>
        item.column !== column && item.newValue !== newValue
    );
    setTransformedValues(newTransformedValues);
  };

  //Elimina todos los par de columna y valor del array de transformaciones
  const handleDeleteAllTransform = () => {
    setTransformedValues([]);
  };

  const valueChangeHandler = (value: any, action: ActionMeta<any>) => {
    switch (action.action) {
      case "select-option":
        setValueSelected(value.value);
        break;
      case "clear":
        setValueSelected("");
        break;
      case "create-option":
        valueSelectRef.current.value = value.value;
        setValueSelected(value.value);
        break;
      default:
        break;
    }
  };

  const columnChangeHandler = (value: any, action: ActionMeta<any>) => {
    switch (action.action) {
      case "select-option":
        setColumnSelected(value.value);
        valueSelectRef.current.clearValue();
        setValueSelected("");
        break;
      case "clear":
        setColumnSelected("");
        valueSelectRef.current.clearValue();
        setValueSelected("");
        break;
      default:
        break;
    }
  };

  //Chequea si la columna y el valor ya existían en el array de transformaciones. En caso que sí, si el valor es diferente lo reemplaza, si es igual setea un mensaje avisando, y si son diferentes, guarda el nuevo par de columna y valor en el array para enviar al back.
  const handleAddTransform = () => {
    if (columnSelected && valueSelected) {
      const isColumnInTransformedValues = transformedValues.find(
        (item: Transformation) => item.column === columnSelected
      );
      const isValueInTransformedValues = transformedValues.find(
        (item: Transformation) => item.newValue === valueSelected
      );
      if (isColumnInTransformedValues) {
        if (isValueInTransformedValues) {
          setNewValueError(t("expensesSettings.modalContent.valueExists"));
        } else {
          const filteredTransformedValues = transformedValues.filter(
            (item: Transformation) => {
              return item.column !== columnSelected;
            }
          );
          setTransformedValues([
            ...filteredTransformedValues,
            {
              column: columnSelected,
              newValue: valueSelected,
            },
          ]);
          setNewValueError("");
          columnSelectRef.current.clearValue();
          valueSelectRef.current.clearValue();
          setColumnSelected("");
          setValueSelected("");
        }
      } else {
        const newTransformedValues = [...transformedValues];
        newTransformedValues.push({
          column: columnSelected,
          newValue: valueSelected,
        });
        setNewValueError("");
        setTransformedValues(newTransformedValues);
        columnSelectRef.current.clearValue();
        valueSelectRef.current.clearValue();
        setColumnSelected("");
        setValueSelected("");
      }
    } else {
      setNewValueError(t("expensesSettings.modalContent.emptyFieldsCannotAdded"));
    }
  };

  return (
    <Stack height="50vh" direction="column" justifyContent="space-between">
      <form className="es_form_box" id="es-form">
        <div className="es_selects_container">
          <div className="es_modify_column">
            <CustomSelect
              reference={columnSelectRef}
              options={optionsColumns}
              isClearable
              className="es_form_input"
              onChange={columnChangeHandler}
              closeMenuOnSelect
              placeholder={t("expensesSettings.modalContent.chooseColumn")}
            />
            <h2 className="es_modify_title">Gasto</h2>
            {transformedValues.length !== 0 &&
              transformedValues.map((item: any) => (
                <p
                  key={`${item.column},${item.value}`}
                  className="es_modify_description"
                >
                  {item.column}
                </p>
              ))}
          </div>
          <div className="es_modify_column">
            <CreatableSelect
              ref={valueSelectRef}
              isClearable
              formatCreateLabel={(userInput) => `${t("expensesSettings.modalContent.add")} ${userInput}`}
              options={optionsValues}
              className="es_form_input"
              onChange={valueChangeHandler}
              closeMenuOnSelect
              isSearchable
              placeholder={t("expensesSettings.modalContent.chooseAddValue")}
              createOptionPosition="first"
            />
            <h2 className="es_modify_title">
              {t("expensesSettings.modalContent.newValue")}
            </h2>
            {transformedValues.length !== 0 &&
              transformedValues.map((item: any) => (
                <p
                  key={`${item.column},${item.value}`}
                  className="es_modify_description"
                >
                  {item.newValue}
                </p>
              ))}
          </div>
          <div className="es_buttons_column">
            <Button
              onClick={handleAddTransform}
              variant="contained"
              sx={{
                minWidth: "20px !important",
                borderRadius: "100%",
                width: "40px",
                height: "40px",
                backgroundColor: "#32648e",
                boxShadow: "5px 5px 10px var(--text-info)",
                transition: "all 0.4s",
                ":hover": {
                  backgroundColor: "var(--white)",
                  color: "#32648e",
                  border: "1px solid #32648e"
                },
                ":active": { scale: "0.95" },
              }}
            >
              <AddIcon sx={{ fontSize: "30px", fontWeight: "bold" }} />
            </Button>
            <h2 className="es_modify_title">
              {transformedValues.length !== 0 && (
                <DeleteForever
                  tooltipPlacement="left"
                  onClick={handleDeleteAllTransform}
                />
              )}
            </h2>
            {transformedValues.length !== 0 &&
              transformedValues.map((item: any) => (
                <p
                  key={`${item.column},${item.value}`}
                  className="es_modify_description_icons"
                >
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={t("expensesSettings.modalContent.deleteRow")}
                    placement="left"
                  >
                    <DeleteIcon
                      onClick={() =>
                        handleDeleteTransform(item.column, item.value)
                      }
                      sx={{
                        color: "var(--text-info)",
                        padding: "0",
                        margin: "0",
                        "& :hover": { color: "#F90D4A", cursor: "pointer" },
                      }}
                    />
                  </Tooltip>
                </p>
              ))}
          </div>
        </div>
      </form>
      <Stack>
        <p className="es_newValueError">{newValueError}</p>
        <p className="es_info_rowsToTransform">
          {transformedValues.length === 0 ? (
            t("expensesSettings.modalContent.noTransToApply")
          ) : (
            <>
              {t("expensesSettings.modalContent.changeWillAffect")}{selectedRows.length} {t("expensesSettings.modalContent.rows")}.
            </>
          )}
        </p>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomButton
            title={t("expensesSettings.modalContent.cancel")}
            color="light-grey"
            type="button"
            onClick={handleToggleTransformModal}
          />
          <CustomButton
            title={t("expensesSettings.modalContent.accept")}
            color="blue-greeny"
            type="button"
            onClick={handleToggleConfirmationModal}
            disabled={transformedValues.length === 0}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ModalContent;
