import { getBackgroundColor } from "../criterias/schemaManagement/utils";
import { grid_item_element_styles } from "../../styles/app-styles";
import { Message } from "../criterias/configCriteria/types";
import { selectStyles } from "../criterias/configCriteria/select.styles";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useRef, useState } from "react";
import AddButton from "../buttons/AddButton";
import Box from "@mui/material/Box";
import Button from "../buttons/Button";
import CheckmarkSelect from "../periods/CheckmarkSelect";
import CustomInput from "../forms/CustomInput";
import FormSelectLayout from "../forms/FormSelectLayout";
import MessageBlock from "../forms/MessageBlock";
import SchemaPeriodRow from "./SchemaPeriodRow";
import Select from "../forms/Select";
import Stack from "@mui/material/Stack";
import useApi from "../../hooks/useApi";
import useLanguage from "../../context/LanguageProvider";
import useSingleSelect from "../../hooks/useSingleSelect";
import {
  formatOptions,
  handleElementRepeatedNameTypeMessage,
} from "../../utils/util";
import {
  PERIODS_URL,
  PREPRORRATEO_FLOW_URL,
  PREPRORRATION_LINKFLOW_URL,
  SCHEMAS_URL,
} from "../../api/axios";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";

const SchemaPeriodContentModal = ({
  handleToggleModal,
  selectedRows,
  tabValue,
  dataFlows,
  refetchPreproration,
}) => {
  const { t } = useLanguage();
  const inputFlowNameRef: any = useRef<HTMLInputElement>(null);
  const schemaSelectRef: any = useRef(null);
  const [schemaRows, setSchemaRows] = useState<any[]>([]);
  const areSchemaRows = schemaRows && schemaRows?.length > 0;
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>([]);
  const [flowName, setFlowName] = useState<string>("");
  const [message, setMessage] = useState<Message>({
    text: "",
    type: "error",
  });

  const { data: dataGetEnabledPeriods } = useApiQuery(
    `${PERIODS_URL}?gastos=true`
  );

  const flow = { id: 0 };
  const configMode = "CREATE";

  const handleChangeValue = (value: any) => {
    handleElementRepeatedNameTypeMessage(
      t,
      value,
      dataFlows,
      flow?.id,
      configMode,
      setMessage
    );
    setFlowName(value);
  };

  const [
    actionSelectedOption,
    ,
    actionSelectedOptionChangeHandler,
  ] = useSingleSelect(undefined, {
    value: "nuevo_flujo",
    label: t("Crear nuevo flujo"),
  });

  const [
    flowSelectedOption,
    ,
    flowSelectedOptionChangeHandler,
  ] = useSingleSelect();

  const [
    schemaSelectedOption,
    setSchemaSelectedOption,
    schemaSelectedOptionChangeHandler,
  ] = useSingleSelect();

  const [
    defaultSchemaSelectedOption,
    ,
    defaultSchemaSelectedOptionChangeHandler,
  ] = useSingleSelect();

  const { data: dataSchemas } = useApiQuery(SCHEMAS_URL, false, undefined);

  const onSuccessPostFlow = () => {
    refetchPreproration();
    handleToggleModal();
  };

  const { isLoading: isLoadingPostFlow, callApi: postFlow } = useApi(
    PREPRORRATEO_FLOW_URL,
    "POST",
    undefined,
    undefined,
    onSuccessPostFlow
  );

  const { isLoading: isLoadingLinkFlow, callApi: linkFlow } = useApi(
    PREPRORRATION_LINKFLOW_URL,
    "POST",
    undefined,
    undefined,
    onSuccessPostFlow
  );

  const handleApply = () => {
    let completedInfo: any = undefined;
    if (actionSelectedOption?.value === "nuevo_flujo") {
      completedInfo = {
        nombre: flowName,
        esquema_default: defaultSchemaSelectedOption?.value,
        esquemas_periodos: schemaRows.map((schemaRow) => ({
          esquema: schemaRow.esquema.value,
          periodos: schemaRow.periodos,
        })),
        grupos: selectedRows,
        tipo_destino: tabValue,
      };
      postFlow(undefined, completedInfo);
    } else {
      completedInfo = {
        grupos: selectedRows,
        tipo_destino: tabValue,
      };
      flowSelectedOption &&
        linkFlow(
          PREPRORRATION_LINKFLOW_URL(flowSelectedOption.value),
          completedInfo
        );
    }
  };

  const isValidSchemaLine2 = (t, schemaRows, setMessage) => {
    const addedPeriods =
      (schemaRows &&
        schemaRows.length > 0 &&
        schemaRows.map((schemaRow) => schemaRow.periodos)) ||
      [];
    const addedFlattenPeriods = addedPeriods.flat();
    const repeatedPeriods =
      addedFlattenPeriods.filter((period) =>
        selectedPeriods.includes(period)
      ) || [];

    if (repeatedPeriods?.length > 0) {
      setMessage({
        text: t("No se pueden agregar periodos que ya han sido seleccionados."),
        type: "error",
      });
      return false;
    }
    if (
      !selectedPeriods ||
      selectedPeriods.length === 0 ||
      !schemaSelectedOption
    ) {
      setMessage({
        text: t("Se deben completar todos los campos"),
        type: "error",
      });
      return false;
    }
    return true;
  };

  const handleDeleteSchema = (index) => {
    const newSchemaRows = schemaRows.filter((schemaRow, i) => i !== index);
    setSchemaRows(newSchemaRows);
  };

  const handleAddSchemaLine = () => {
    if (isValidSchemaLine2(t, schemaRows, setMessage)) {
      setSchemaRows((prev) => [
        ...prev,
        {
          periodos: selectedPeriods,
          esquema: schemaSelectedOption,
        },
      ]);
      setMessage({
        text: "",
        type: "error",
      });
      setSelectedPeriods([]);
      schemaSelectRef.current.clearValue();
      setSchemaSelectedOption(undefined);
    }
  };

  return (
    <Stack width="100%" gap={2} justifyContent="flex-start">
      <SimpleBackdrop
        open={
          isLoadingPostFlow ||
          isLoadingLinkFlow
        }
        message={t("general.loading")}
      />
      <Stack
        sx={{
          width: "100%",
          minWidth: "800px",
          mb: "5px",
          gap: 1,
          justifyContent: "flex-start",
        }}
      >
        <Box width="100%">
          <FormSelectLayout title={"¿Qué desea hacer?"} margin="0 0 0 0">
            <Select
              styles={selectStyles(actionSelectedOption)}
              options={[
                {
                  value: "nuevo_flujo",
                  label: t("Crear nuevo flujo"),
                },
                {
                  value: "flujo_existente",
                  label: t("Aplicar flujo existente"),
                },
              ]}
              placeholder={t("general.selectOption")}
              defaultValue={{
                value: "nuevo_flujo",
                label: t("Crear nuevo flujo"),
              }}
              closeMenuOnSelect
              onChange={actionSelectedOptionChangeHandler}
              isClearable
              isSearchable
            />
          </FormSelectLayout>
        </Box>
        {actionSelectedOption?.value === "nuevo_flujo" && (
          <>
            <FormSelectLayout title={t("Nombre del flujo")} margin="0 0 0 0">
              <CustomInput
                name="FlowName"
                inputRef={inputFlowNameRef}
                value={flowName}
                setValue={handleChangeValue}
                maxLength={50}
                required={true}
                placeholder={`${t("Escribir nombre")}...`}
                autoFocus={true}
              />
            </FormSelectLayout>
            <Box width="100%">
              <FormSelectLayout title={"Esquema por default"} margin="0 0 0 0">
                <Select
                  styles={selectStyles(defaultSchemaSelectedOption)}
                  options={dataSchemas?.filter(
                    sch => sch?.tipo_destino === tabValue
                  )?.map((schema: any) => ({
                    value: schema.id,
                    label: schema.nombre,
                  }))}
                  placeholder={t("general.selectOption")}
                  defaultValue={defaultSchemaSelectedOption}
                  closeMenuOnSelect
                  onChange={defaultSchemaSelectedOptionChangeHandler}
                  isClearable
                  isSearchable
                />
              </FormSelectLayout>
            </Box>
            <MessageBlock message={message} />
            <Stack
              direction="row"
              justifyContent={"space-between"}
              width="100%"
              gap={1}
            >
              <Stack width={"50%"} gap={0.7}>
                <Stack
                  sx={{
                    ...grid_item_element_styles(
                      getBackgroundColor,
                      "header",
                      () => "100%"
                    ),
                    color: "white",
                    minHeight: "30px",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                >
                  Periodo
                </Stack>
                {dataGetEnabledPeriods && (
                  <CheckmarkSelect
                    dates={dataGetEnabledPeriods}
                    selectedPeriods={selectedPeriods}
                    setSelectedPeriods={setSelectedPeriods}
                    width="100%"
                    height="38px"
                    selectLabel=""
                  />
                )}
              </Stack>
              <Stack width={"50%"} gap={0.7}>
                <Stack
                  sx={{
                    ...grid_item_element_styles(
                      getBackgroundColor,
                      "header",
                      () => "100%"
                    ),
                    color: "white",
                    minHeight: "30px",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                >
                  Esquema
                </Stack>
                <Select
                  reference={schemaSelectRef}
                  styles={selectStyles(schemaSelectedOption)}
                  options={dataSchemas.map((schema: any) => ({
                    value: schema.id,
                    label: schema.nombre,
                  }))}
                  placeholder={t("general.selectOption")}
                  defaultValue={schemaSelectedOption}
                  closeMenuOnSelect
                  onChange={schemaSelectedOptionChangeHandler}
                  isClearable
                  isSearchable
                />
              </Stack>
              <Stack
                justifyContent={"space-between"}
                sx={{
                  margin: 0,
                  justifyContent: "flex-end",
                  display: "flex",
                  height: "74px",
                }}
              >
                <AddButton onClick={handleAddSchemaLine} />
              </Stack>
            </Stack>
            <Stack width="100%" gap={1} minHeight={"120px"}>
              {areSchemaRows &&
                schemaRows.map((schemaRow, i) => (
                  <SchemaPeriodRow
                    key={i}
                    schemaRow={schemaRow}
                    handleDeleteSchema={handleDeleteSchema}
                    index={i}
                    dataPeriods={dataGetEnabledPeriods}
                  />
                ))}
            </Stack>
          </>
        )}

        {actionSelectedOption?.value === "flujo_existente" && (
          <Box width="100%" minHeight={"358px"}>
            <FormSelectLayout title={"Seleccionar flujo"} margin="0 0 0 0">
              <Select
                styles={selectStyles(flowSelectedOption)}
                options={formatOptions(dataFlows)}
                placeholder={t("general.selectOption")}
                defaultValue={flowSelectedOption}
                closeMenuOnSelect
                onChange={flowSelectedOptionChangeHandler}
                isClearable
                isSearchable
              />
            </FormSelectLayout>
          </Box>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ width: "100%" }}
        gap={2}
      >
        <Button
          title={t("periods.periodsDialogContent.cancel")}
          color="grey"
          type="button"
          onClick={handleToggleModal}
          margin={false}
        />
        <Button
          title={
            actionSelectedOption?.value === "flujo_existente"
              ? t("periods.periodsDialogContent.apply")
              : t("Crear y aplicar")
          }
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={
            actionSelectedOption?.value === "nuevo_flujo"
              ? !flowName ||
                flowName.trim() === "" ||
                !defaultSchemaSelectedOption
              : !flowSelectedOption
          }
          margin={false}
        />
      </Stack>
    </Stack>
  );
};

export default SchemaPeriodContentModal;
