import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
import HC_more from "highcharts/highcharts-more";
import Treemap from "highcharts/modules/treemap";
import { memo } from "react";
import exporting from "highcharts/modules/exporting";
import accessibility from "highcharts/modules/accessibility"; 

exporting(Highcharts);
interface Props {
  results: any;
}

type DrillDownTreeMapPoint = {
  id: string;
  name: string;
  color: string;
  value?: number;
  parent?: string;
};

const TreemapDrilldownChart = memo((props: Props) => {
  drilldown(Highcharts);
  Treemap(Highcharts);
  HC_more(Highcharts);
  accessibility(Highcharts);
  exporting(Highcharts);

  const { results } = props;  

  const colors: string[] = [
    "var(--chart-1)",
    "var(--chart-2)",
    "var(--chart-3)",
    "var(--chart-4)",
    "var(--chart-5)",
    "var(--chart-6)",
    "var(--chart-7)",
    "var(--chart-8)",
    "var(--chart-9)",
    "var(--chart-10)",
    "var(--chart-11)",
    "var(--chart-12)",
    "var(--chart-13)",
    "var(--chart-14)",
    "var(--chart-15)",
    "var(--chart-16)",
    "var(--chart-17)",
    "var(--chart-18)",
    "var(--chart-19)",
    "var(--chart-20)",
    "var(--chart-21)",
  ];

  let indexColor = 0;

  const cycleArray = (array: any[]) => {
    return function () {
      const current = array[indexColor % array.length];
      indexColor++;
      return current;
    };
  };

  const options = {
    chart: {
      type: "treemap",
      style: {
        width: "100%",
      },
    },
    title: false /* { text: results.titulo_grafico } */,
    accesisbility: {
      enabled: true,
    },
    series: [
      {
        name: results?.titulo_grafico,
        type: "treemap",
        layoutAlgorithm: "squarified",
        allowDrillToNode: true,
        animationLimit: 1000,
        dataLabels: {
          enabled: false,
        },
        levels: [
          {
            level: 1,
            dataLabels: {
              enabled: true,
            },
            borderWidth: 3,
            levelIsConstant: false,
          },
          {
            level: 1,
            dataLabels: {
              style: {
                fontSize: "16px",
              },
            },
          },
          {
            level: 2,
            dataLabels: {
              style: {
                fontSize: "16px",
              },
            },
          },
        ],
        accessibility: {
          exposeAsGroupOnly: true,
          enabled: true,
        },
        data: results?.series.map((serie: any) => {
          return { ...serie, color: cycleArray(colors)() };
        }),
      },
    ],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
});

export default TreemapDrilldownChart;
