import { getMessages } from "../utils/util";
import { useSnackbar, VariantType } from "notistack";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export type Messages = {
  [key: number]: string;
} & {
  200?: string;
  201?: string;
  204?: string;
  400?: string;
  401?: string;
  403?: string;
  404?: string;
  500?: string;
};

type Status = 200 | 201 | 204 | 400 | 401 | 403 | 404 | 500;

const useApi = (
  url: any,
  method: "GET" | "POST" | "PUT" | "DELETE",
  messages?: Messages,
  body?: { [key: string]: any } | any[],
  onSuccess?: (...args: any[]) => any,
  onError?: any,
  hasSuccessSnackbar?: boolean,
  controller?: any,
  downloadFile?: boolean,
  hasErrorSnackbar?: boolean
) => {
  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ),
    });
  };

  const isSuccessfulStatus = (status: Status) => status >= 200 && status < 300;

  const handleSuccessResponse = (
    status: Status,
    data: any,
    extraData?: any
  ) => {
    setError(null);
    setData(data);
    hasSuccessSnackbar !== false && showSuccessSnackbar(status);
    if (downloadFile && extraData) {
      downloadFileFromData(data, extraData.fileType, extraData.fileName);
    }
    onSuccess && onSuccess(data, extraData, status);
  };

  const showSuccessSnackbar = (status: Status) => {
    const message =
      (messages && getMessages(messages)[status]) || "Operación exitosa";
    handleClickVariant(message, "success");
  };

  const downloadFileFromData = (
    data: any,
    fileType: string,
    fileName: string
  ) => {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: fileType })
    );
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  };

  const handleUndefinedStatus = (data: any) => {
    setError(data);
  };

  const handleErrorStatus = (status: Status, data: any) => {
    setError(data || true);
    onError && onError();
    const errorMessage = data.mensaje
      ? data.mensaje
      : messages
      ? getMessages(messages)[status]
      : "Hubo un error, intentelo más tarde.";
    handleClickVariant(errorMessage, "error");
  };

  const handleResponse = (status: Status, data: any, extraData?: any) => {
    if (isSuccessfulStatus(status)) {
      handleSuccessResponse(status, data, extraData);
    } else if (status === undefined) {
      handleUndefinedStatus(data);
    } else {
      handleErrorStatus(status, data);
    }
    setIsLoading(false);
  };

  const handlePostRequest = async (
    url: string,
    body?: any,
    extraData?: any
  ) => {
    const requestBody = getRequestBody(body, extraData);
    const response = await axiosPrivate.post(
      url,
      requestBody.body,
      requestBody.config
    );
    handleResponse(response.status as Status, response.data, extraData);
  };

  const handlePutRequest = async (
    url: string,
    body,
    alternativeBody,
    extraData?: any
  ) => {
    if (body && alternativeBody) {
      handleResponse(400, "Información para enviar duplicada.");
    } else if (!body && !alternativeBody) {
      const response = await axiosPrivate.put(url);
      handleResponse(response.status as Status, response.data);
    } else {
      const requestBody = getRequestBody(alternativeBody || body, extraData);
      const response = await axiosPrivate.put(
        url,
        requestBody.body,
        requestBody.config
      );
      handleResponse(response.status as Status, response.data, extraData);
    }
  };

  const handleDeleteRequest = async (
    url: string,
    body?: any,
    extraData?: any
  ) => {
    const requestBody = getRequestBody(body, extraData);
    const response = await axiosPrivate.delete(url, { data: requestBody.body });
    handleResponse(response.status as Status, response.data, extraData);
  };

  const getRequestConfig: any = () => {
    return downloadFile
      ? { responseType: "blob" }
      : controller
      ? { signal: controller.signal }
      : {};
  };

  const getRequestBody = (body?: any, extraData?: any) => {
    let requestBody: any;
    let config: any = {};

    if (extraData === "UPLOAD FILE") {
      requestBody = body;
      config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    } else {
      requestBody = JSON.stringify(body);
      if (downloadFile) {
        config.responseType = "blob";
      }
    }

    return { body: requestBody, config };
  };

  const handleGetRequest = async (url: string, extraData?: any) => {
    const response = await axiosPrivate.get(url, getRequestConfig(extraData));
    handleResponse(response.status as Status, response.data, extraData);
  };

  const callApi = async (
    alternativeURL?: string,
    alternativeBody?: any,
    extraData?: any
  ) => {
    try {
      setIsLoading(true);
      const requestUrl = alternativeURL || url;

      switch (method) {
        case "GET":
          await handleGetRequest(requestUrl, extraData);
          break;
        case "POST":
          await handlePostRequest(
            requestUrl,
            alternativeBody || body,
            extraData
          );
          break;
        case "PUT":
          await handlePutRequest(requestUrl, body, alternativeBody, extraData);
          break;
        case "DELETE":
          await handleDeleteRequest(
            requestUrl,
            alternativeBody || body,
            extraData
          );
          break;
        default:
          break;
      }
    } catch (error: any) {
      console.error("error", error);
      if (error.message !== "canceled") {
        handleResponse(error?.request?.status as Status, error?.response?.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, callApi };
};

export default useApi;
