import { columnsTypeOptions } from "../data";
import { IdOption } from "../../../criterias/configCriteria/types";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { useRef, useState } from "react";
import Button from "../../../buttons/Button";
import CheckboxOption from "../../../forms/CheckboxOption";
import CustomInput from "../../../forms/CustomInput";
import FileNameInput from "../Shared/FileNameInput";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import MessageBlock from "../../../forms/MessageBlock";
import Radio from "@mui/material/Radio";
import Select from "../../../forms/Select";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TextAreaAutoSize from "../../../forms/TextAreaAutoSize";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import {
  column_type_checkbox_container,
  column_type_replace_container,
  key_column_select_styles,
} from "../../../../styles/app-styles";
import {
  COLUMN_FILE_URL,
  COMPANY_PARAMETERS_FILE_URL,
} from "../../../../api/axios";

const ColumnType = ({
  column,
  file,
  handleClose,
  getFilesParams,
  setSelectedRows,
}) => {
  const { t } = useLanguage();
  const columnSelectRef: any = useRef(null);
  const [columnName, setfColumnName] = useState(column?.nombre || "");
  const [description, setDescription] = useState(column?.descripcion || "");

  const defaultValue = column?.tipo
    ? ({
        value: column?.tipo,
        label: columnsTypeOptions?.find((item) => item.value === column.tipo)
          ?.label,
      } as IdOption)
    : undefined;
  const [
    columnSelectedOption,
    ,
    columnOptionChangeHandler,
  ] = useSingleSelect(undefined, defaultValue);
  const [columnValidations, setColumnValidations] = useState<any>({
    requerida: column?.requerida ? column.requerida : false,
    valores_nulos: column?.valores_nulos ? column.valores_nulos : true,
    valores_unicos: column?.valores_unicos ? column.valores_unicos : false,
  });
  const [replace, setReplace] = useState<boolean>(
    column?.reemplazar ? true : false
  );
  const [numericValue, setNumericValue] = useState<string | number>(
    column?.reemplazar ? column.reemplazar : ""
  );
  const [message, setMessage] = useState({
    type: "error",
    text: "",
  });
  const toggleColumnValidations = (value: any) => {
    if (value === "requerida") {
      setColumnValidations((prev) => {
        return { ...prev, requerida: !columnValidations.requerida };
      });
    } else if (value === "valoresNulos") {
      setColumnValidations((prev) => {
        return { ...prev, valores_nulos: !columnValidations.valores_nulos };
      });
    } else if (value === "valoresUnicos") {
      setColumnValidations((prev) => {
        return { ...prev, valores_unicos: !columnValidations.valores_unicos };
      });
    }
  };
  const handleChange = (event: any) => {
    setNumericValue(event.target.value);
  };

  const onSuccessPostColumn = () => {
    getFilesParams(`${COMPANY_PARAMETERS_FILE_URL}/${file.id}`);
    setSelectedRows([]);
    handleClose();
  };

  const handleSetNumericValue = (value: string | number) => {
    //
    if(columnSelectedOption?.value === "numerica"){
      if (!isNaN(Number(value))) {
        setNumericValue(value);
      }
    } else if (columnSelectedOption?.value === "entero") {
      const regex = /^\d*$/;
      if (regex.test(String(value))) {
        setNumericValue(value);
      }
    }
  }

  const { isLoading: isLoadingPostColumn, callApi: postColumn } = useApi(
    COLUMN_FILE_URL(file.id),
    "POST",
    undefined,
    undefined,
    onSuccessPostColumn,
    undefined,
    true
  );

  const { isLoading: isLoadingPutSingleColumn, callApi: putSingleColumn } =
    useApi(
      `${COLUMN_FILE_URL(file.id)}/${column?.id}`,
      "PUT",
      undefined,
      undefined,
      onSuccessPostColumn,
      undefined,
      true
    );

  const { isLoading: isLoadingPutMultiColumn, callApi: putMultiColumn } =
    useApi(
      COLUMN_FILE_URL(file.id),
      "PUT",
      undefined,
      undefined,
      onSuccessPostColumn,
      undefined,
      true
    );

  const handleAccept = () => {
    const updatedColumn = {
      id: column?.id,
      nombre: columnName,
      descripcion: description !== "" ? description : undefined,
      tipo: columnSelectedOption?.value,
      reemplazar: replace ? numericValue : "",
      requerida: columnValidations.requerida,
      valores_nulos: columnValidations.valores_nulos,
      valores_unicos: columnValidations.valores_unicos,
    };
    if (column?.id && column.id.length > 1) {
      putMultiColumn(undefined, updatedColumn);
    } else if (column?.id && column.id.length === 1) {
      putSingleColumn(undefined, updatedColumn);
    } else {
      postColumn(undefined, updatedColumn);
    }
  };

  return (
    <Stack sx={{ width: "100%" }}>
      <SimpleBackdrop
        open={
          isLoadingPostColumn ||
          isLoadingPutSingleColumn ||
          isLoadingPutMultiColumn
        }
        message={t("general.loading")}
      />
      <MessageBlock message={message} />
      <Stack sx={{ ...key_column_select_styles, gap: 1 }}>
        {(!column || (column?.id && column.id.length === 1)) && (
          <Stack sx={{ width: "100%" }}>
            <FileNameInput
              fileNameRef={columnSelectRef}
              fileName={columnName}
              setfileName={setfColumnName}
              setMessage={setMessage}
              dataFilesList={file?.columnas || []}
              label={t('general.name')}
              placeholder={t('companyParam.columnName')}
            />
          </Stack>
        )}
        <Stack sx={{ width: "100%" }}>
          <FormSelectLayout title={t('companyParam.dataType')} required={true}>
            <Select
              reference={columnSelectRef}
              isClearable
              styles={selectStyles(columnSelectedOption)}
              options={columnsTypeOptions}
              className="pp_select_schema"
              onChange={columnOptionChangeHandler}
              closeMenuOnSelect
              isSearchable
              placeholder={t(
                "preprorationParameterization.newSchemaContent.chooseAddValue"
              )}
              defaultValue={defaultValue}
            />
          </FormSelectLayout>
        </Stack>
      </Stack>
      {(!column || (column?.id && column.id.length === 1)) && (
        <FormSelectLayout title={t('companyParam.description')}>
          <TextAreaAutoSize
            placeholder={t('companyParam.fileDescription')}
            value={description}
            setValue={setDescription}
          />
        </FormSelectLayout>
      )}
      <Stack sx={column_type_checkbox_container}>
        <CheckboxOption
          toggleCheckbox={() => toggleColumnValidations("requerida")}
          checked={columnValidations.requerida}
          id={`requerida-${column?.label}-${file.nombre}`}
          labelText={t("companyParam.required")}
        />
        <CheckboxOption
          toggleCheckbox={() => toggleColumnValidations("valoresNulos")}
          checked={columnValidations.valores_nulos}
          id={`valoresNulos-${column?.label}-${file.nombre}`}
          labelText={t("companyParam.allowNullValues")}
        />
        <CheckboxOption
          toggleCheckbox={() => toggleColumnValidations("valoresUnicos")}
          checked={columnValidations.valores_unicos}
          id={`valoresUnicos-${column?.label}-${file.nombre}`}
          labelText={t("companyParam.onlyUniqueValues")}
        />
      </Stack>
      {columnSelectedOption &&
        (columnSelectedOption?.value === "numerica" ||
          columnSelectedOption?.value === "entero") && (
          <Stack sx={column_type_replace_container}>
            <CheckboxOption
              toggleCheckbox={() => setReplace(!replace)}
              checked={replace}
              id="reemplazar"
              labelText={t("companyParam.replaceNoNumeric")}
              minWidth={"250px"}
            />
            <Stack
              sx={{
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <Radio
                checked={numericValue === "PROMEDIO"}
                onChange={handleChange}
                value={"PROMEDIO"}
                name="radio-buttons"
                id="promedio"
                disabled={!replace}
              />
              <label
                style={{
                  color: "var(--text-main)",
                  fontSize: "14px",
                }}
                htmlFor={"promedio"}
              >
                {t("companyParam.average")}
              </label>
            </Stack>
            <Radio
              checked={numericValue !== "PROMEDIO"}
              onChange={handleChange}
              value={numericValue !== "PROMEDIO" ? numericValue : ""}
              name="radio-buttons"
              disabled={!replace}
            />
            <CustomInput
              value={numericValue !== "PROMEDIO" ? numericValue : ""}
              setValue={handleSetNumericValue}
              placeholder={t("companyParam.value")}
              disabled={!replace}
            />
          </Stack>
        )}
      <Stack
        sx={{ width: "100%", flexDirection: "row", justifyContent: "center", mt: "50px" }}
      >
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleClose}
          disabled={false}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={handleAccept}
          disabled={
            !columnSelectedOption ||
            ((columnSelectedOption?.value === "numerica" ||
              columnSelectedOption?.value === "entero") &&
              replace &&
              (!numericValue || isNaN(Number(numericValue))))
          }
        />
      </Stack>
    </Stack>
  );
};

export default ColumnType;
