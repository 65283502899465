import { IdOption } from "./types";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../context/LanguageProvider";
import useMediaQuery from "@mui/material/useMediaQuery";

interface VirtualSelectProps {
  incomingValuesOptions: string[];
  selectedValues: string[] | null;
  setSelectedValues: (newValue: string[]) => void;
  setValuesSelectedOptions: (newValue: IdOption[]) => void;
  isLoadingIncomingValuesOptions: boolean;
  selectOneOption?: boolean,
  isDisabled?: boolean,
  defaultValue?: any
}

const VirtualSelect = ({
  incomingValuesOptions,
  selectedValues,
  setSelectedValues,
  setValuesSelectedOptions,
  isLoadingIncomingValuesOptions,
  selectOneOption,
  isDisabled,
  defaultValue
}: VirtualSelectProps) => {

  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);

  const LISTBOX_PADDING = 5; // px

  useEffect(() => {
    if (selectedValues && incomingValuesOptions) {
      if (
        Array.isArray(selectedValues) &&
        selectedValues.every((value) => typeof value === "string")
      ) {
        // Filtrar los elementos de incomingValuesOptions que no están en selectedValues
        const filteredOptions = incomingValuesOptions && incomingValuesOptions?.filter(
          (option) => !selectedValues.includes(option)
        );
        setFilteredOptions(filteredOptions);
      }
    } else {
      incomingValuesOptions && setFilteredOptions(incomingValuesOptions);
    }
  }, [selectedValues, incomingValuesOptions]);

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: "border-box",
      "& ul": {
        padding: 0,
        margin: 0,
      },
    },
  });

  const OuterElementContext = React.createContext({});
  const { t } = useLanguage();

  const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty("group")) {
      return (
        <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
          {dataSet.group}
        </ListSubheader>
      );
    }

    return (
      <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
        {dataSet[1]}
      </Typography>
    );
  }

  // Adapter for react-window
  const ListboxComponent: any = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: any[] = [];
    (children as any[]).forEach((item: any & { children?: any[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: any) => {
      if (child.hasOwnProperty("group")) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index: any) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  return (
    <Autocomplete
      defaultValue={defaultValue && defaultValue}
      disabled={isDisabled}
      value={selectedValues as string[]}
      onChange={
        selectOneOption
          ? (event: any, newValue: string[]) => {
              // Validar si alguna de las nuevas selecciones ya está en selectedValues

              const isAlreadySelected = newValue.some(
                (value) => selectedValues && selectedValues.includes(value)
              );

              if (!isAlreadySelected && setSelectedValues) {
                setSelectedValues(newValue);
                setValuesSelectedOptions(
                  newValue
                    ? newValue.map(
                        (value) =>
                          ({
                            value: value,
                            label: value,
                          } || undefined)
                      )
                    : []
                );
              }
            }
          : (event: any, newValue: string[]) => {
              setSelectedValues && setSelectedValues(newValue);
              setValuesSelectedOptions(
                newValue
                  ? newValue.map(
                      (value) => ({ value: value, label: value } || undefined)
                    )
                  : []
              );
            }
      }
      multiple
      size="small"
      disableCloseOnSelect={selectOneOption ? false : true}
      id="virtualize-demo"
      sx={{
        color: "white",
        "& .MuiFormControl-root .MuiOutlinedInput-root": { padding: "5px" },
      }}
      fullWidth
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={isLoadingIncomingValuesOptions ? [] : filteredOptions}
      noOptionsText={
        isLoadingIncomingValuesOptions ? (
          <p style={{ padding: 0, margin: 0 }}>
            {" "}
            {t("configCriteria.virtualSelect.loading")}
          </p>
        ) : (
          t("configCriteria.virtualSelect.noAvailableOptions")
        )
      }
      getOptionLabel={(option) => JSON.stringify(option)}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          placeholder={
            !selectedValues || selectedValues.length < 1
              ? selectOneOption
                ? t("configCriteria.virtualSelect.selectOneValue")
                : t("configCriteria.virtualSelect.selectValues")
              : ""
          }
        />
      )}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params as unknown as React.ReactNode}
    />
  );
};

export default VirtualSelect;
