import InsertChartIcon from "@mui/icons-material/InsertChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { Filter, IdOption } from "../criterias/configCriteria/types";
import BarrasDrilldown from "../../assets/BarrasDrilldown.png";
import DrilldownCake from "../../assets/DrilldownCake.png";
import waterfall from "../../assets/waterfall.png";
import scatterplot from "../../assets/scatterplot.png";
import Boxplot from "../../assets/Boxplot.png";
import Lines from "../../assets/Lines.png";
import TreeDataDrilldown from "../../assets/TreeDataDrilldown.png";
import WaterfallChartIcon from "@mui/icons-material/WaterfallChart";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import { ChartOfList } from "./types";
import { MultiValue, SingleValue } from "react-select";

export const getIcon = (tipo: string) => {
  switch (tipo) {
    case "BARRAS CON DRILLDOWN":
      return <InsertChartIcon />;
    case "LINEAS":
      return <SsidChartIcon />;
    case "TORTA CON DRILLDOWN":
      return <PieChartIcon />;
    case "TREEMAP CON DRILLDOWN":
      return <ViewQuiltIcon />;
    case "WATERFALL":
      return <WaterfallChartIcon />;
    case "SCATTERPLOT":
      return <ScatterPlotIcon />;

    default:
      return <InsertChartIcon />;
  }
};

export const step2_chart = (filters) =>
  filters
    ? filters.map((uniqueFilter: Filter) => {
        return {
          columna: uniqueFilter.column.value,
          excluir: uniqueFilter.filterType === "EXCLUIR" ? true : false,
          externo: false,
          grafico_archivo_valor_filtra: uniqueFilter.values
            .map((value: any) => {
              if (value.value !== "") {
                return { valor: value.value };
              } else {
                return null;
              }
            })
            .filter((value: any) => value !== null),
        };
      })
    : [];

export const step3_chart = (filtersExternal) =>
  filtersExternal
    ? filtersExternal.map((uniqueFilter: Filter) => {
        return {
          columna: uniqueFilter.column.value,
          excluir: uniqueFilter.filterType === "EXCLUIR" ? true : false,
          externo: true,
          grafico_archivo_valor_filtra: uniqueFilter.values
            .map((value: any) => {
              if (value.value !== "") {
                return { valor: value.value };
              } else {
                return null;
              }
            })
            .filter((value: any) => value !== null),
        };
      })
    : [];

export const step3_validation = (externalFilters) =>
  externalFilters
    ? externalFilters.map((filter) => {
        return {
          grafico_columna_gastos_o_externo: filter.externalColumn.value,
          grafico_columna_archivo_base: filter.columnFile.value,
          externo: true,
        };
      })
    : [];

export const completedInfo = (
  chartName,
  chartType,
  fileSelectedOptions,
  groupSelectedOptions,
  columnAxisYSelectedOptions,
  divisionRows,
  functionSelectedOptions,
  axisYName,
  columnAxisXSelectedOptions,
  axisXName,
  columnBaseSelectedOptions,
  selectedOptions,
  columnSeriesSelectedOptions,
  activeIndicator,
  filters,
  filtersExternal,
  externalFilters,
  auxiliaryLines?: any,
  auxiliaryLinesColor?: any,
  columnValuesScatterSelectedOption?: any
) => {
  return {
    nombre: chartName,
    tipo: chartType,
    archivo: fileSelectedOptions?.value,
    grupo: groupSelectedOptions?.value,
    columna_eje_y:
      chartType === "LINEAS" ||
      chartType === "BARRAS CON DRILLDOWN" ||
      chartType === "SCATTERPLOT"
        ? functionSelectedOptions?.value === "DIVISION"
          ? divisionRows.map((row: any) => {
              return {
                columna: row?.columna?.value,
                columna_divisor: row?.columna_divisor?.value,
              };
            })
          : columnAxisYSelectedOptions.map((option: IdOption) => {
              return { columna: option?.value };
            })
        : undefined,
    funcion: functionSelectedOptions?.value,
    nombre_eje_y: axisYName,
    columna_eje_x: columnAxisXSelectedOptions?.value,
    nombre_eje_x: axisXName,
    columna_base:
      chartType === "TORTA CON DRILLDOWN" ||
      chartType === "TREEMAP CON DRILLDOWN" ||
      chartType === "BOXPLOT"
        ? columnBaseSelectedOptions?.value
        : undefined,
    columnas_drilldown:
      selectedOptions && selectedOptions.length
        ? selectedOptions.map((option: IdOption) => option && option.value)
        : [],
    columna_series:
      (chartType === "LINEAS" ||
        chartType === "BARRAS CON DRILLDOWN" ||
        chartType === "SCATTERPLOT") &&
      columnSeriesSelectedOptions
        ? columnSeriesSelectedOptions?.value
        : undefined,
    columna_valores:
      chartType === "SCATTERPLOT" && columnValuesScatterSelectedOption
        ? columnValuesScatterSelectedOption.value
        : undefined,
    activo: activeIndicator,
    operacion_filtro_aplicacion: filters
      ? filters[0]?.condition === "ó"
        ? "OR"
        : "AND"
      : "",
    operacion_filtro_externo: filtersExternal
      ? filtersExternal[0]?.condition === "ó"
        ? "OR"
        : "AND"
      : "",
    grafico_archivo_columna_filtra: [
      ...step2_chart(filters),
      ...step3_chart(filtersExternal),
    ],
    grafico_archivo_columna_valida: [...step3_validation(externalFilters)],
    lineas_auxiliares: [
      {
        linea_cero: auxiliaryLines?.linea_cero
          ? auxiliaryLinesColor.linea_cero || "#000000"
          : undefined,
        linea_promedio_x: auxiliaryLines?.linea_promedio_x
          ? auxiliaryLinesColor.linea_promedio_x || "#000000"
          : undefined,
        linea_promedio_y: auxiliaryLines?.linea_promedio_y
          ? auxiliaryLinesColor.linea_promedio_y || "#000000"
          : undefined,
      },
    ],
  };
};

export const completedWaterfallInfo = (
  chartName,
  fileSelectedOptions,
  waterfallRows,
  activeIndicator
) => {
  return {
    nombre: chartName,
    archivo: fileSelectedOptions.value,
    tipo: "WATERFALL",
    columnas: waterfallRows.map((row: any) => {
      return {
        columna: row?.column?.value,
        funcion: row.function.value,
        nombre: row.columnName,
      };
    }),
    activo: activeIndicator,
  };
};

export const validateWaterfallRow = (
  columnSelectedOption: SingleValue<IdOption> | undefined,
  functionSelectedOption: SingleValue<IdOption> | undefined,
  waterfallRows: any[],
  columnName: string,
  setMessage: any,
  t: any
) => {
  let result = false;
  if (waterfallRows.find((row) => row.columnName === columnName)) {
    result = false;
    setMessage({
      type: "error",
      text: t(
        "indicatorsManagementTexts.validateWaterfallRow.rowNameAlreadyExists"
      ),
    });
  } else if (
    (columnSelectedOption && functionSelectedOption && columnName) ||
    (columnName &&
      (functionSelectedOption?.value === "suma_total" ||
        functionSelectedOption?.value === "suma_intermedia"))
  ) {
    const repeatedRows =
      waterfallRows?.filter((row) => {
        const columnValueMatches =
          row?.column?.value !== undefined &&
          row?.column?.value === columnSelectedOption?.value;
        const functionValueMatches =
          row?.function?.value !== undefined &&
          row?.function?.value === functionSelectedOption?.value;

        return columnValueMatches && functionValueMatches;
      }) || [];
    if (repeatedRows && repeatedRows.length > 0) {
      result = false;
      setMessage({
        type: "error",
        text: t("indicatorsManagementTexts.validateWaterfallRow.repeteadLines"),
      });
    } else {
      result = true;
    }
  } else {
    setMessage({
      type: "error",
      text: t(
        "indicatorsManagementTexts.validateWaterfallRow.allFieldsMustBeCompleted"
      ),
    });
  }

  return result;
};

export const formatRow = (
  columnSelectedOption: any,
  functionSelectedOption: any,
  waterfallColumnName: string
) => {
  return {
    column: columnSelectedOption,
    function: functionSelectedOption,
    columnName: waterfallColumnName,
  };
};

export const prepareFilters = (externo: boolean, chart) =>
  chart?.grafico_archivo_columna_filtra
    ?.filter((item: any) => item.externo === externo)
    .map((columnChart: any) => {
      return {
        id: columnChart.columna_id,
        condition: chart.operacion_filtro_aplicacion === "AND" ? "y" : "ó",
        column: {
          value: columnChart.columna_id,
          label: columnChart.columna,
        },
        values: columnChart.grafico_archivo_valor_filtra.map((valor: any) => {
          return { value: valor.valor, label: valor.valor };
        }),
        filterType: columnChart.excluir ? "EXCLUIR" : "INCLUIR",
      };
    });

export const prepareUnions = (externo: boolean, chart) =>
  chart?.grafico_archivo_columna_valida
    ?.filter((item: any) => item.externo === externo)
    .map((columnChart: any) => {
      if (externo) {
        return {
          id: `${columnChart.grafico_columna_archivo_base_id}-${columnChart.grafico_columna_gastos_o_externo_id}`,
          columnFile: {
            value: columnChart.grafico_columna_archivo_base_id,
            label: columnChart.grafico_columna_archivo_base,
          },
          externalColumn: {
            value: columnChart.grafico_columna_gastos_o_externo_id,
            label: columnChart.grafico_columna_gastos_o_externo,
          },
          externalFile: chart.grafico_externo_validacion,
        };
      } else {
        return {
          id: `${columnChart.grafico_columna_archivo_base_id}-${columnChart.grafico_columna_gastos_o_externo_id}`,
          expensesColumn: {
            value: columnChart.grafico_columna_gastos_o_externo_id,
            label: columnChart.grafico_columna_gastos_o_externo,
          },
          column: {
            value: columnChart.grafico_columna_archivo_base_id,
            label: columnChart.grafico_columna_archivo_base,
          },
        };
      }
    });

export function hasRepeteadNumbers(arr) {
  // Utilizar filter() y indexOf() para encontrar elementos repetidos
  return arr.filter((num, index) => arr.indexOf(num) !== index).length > 0;
}

export const handleRepeatedName = (
  chartName,
  charts,
  configMode,
  setMessage,
  chart,
  chartType,
  selectedOptions,
  columnBaseSelectedOptions,
  columnAxisXSelectedOptions,
  columnAxisYSelectedOptions,
  columnSeriesSelectedOptions
) => {
  if (charts && chartName) {
    const chartNameExists = charts?.find(
      (chart: any) => chart.nombre.toLowerCase() === chartName.toLowerCase()
    );
    if (chartNameExists && (configMode === "CREATE" || configMode === "COPY")) {
      setMessage({
        type: "error",
        text: "Ya existe un gráfico con este nombre",
      });
    } else if (
      chartNameExists &&
      configMode === "EDIT" &&
      chart?.nombre.toLowerCase() !== chartName.toLowerCase()
    ) {
      setMessage({
        type: "error",
        text: "Ya existe un gráfico con este nombre",
      });
    } else {
      setMessage(undefined);
    }
  }
  if (
    chartType === "TORTA CON DRILLDOWN" ||
    chartType === "TREEMAP CON DRILLDOWN"
  ) {
    if (
      (selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions[0] &&
        hasRepeteadNumbers(
          selectedOptions.map((option: any) => option.value)
        )) ||
      (selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions[0] &&
        columnBaseSelectedOptions &&
        selectedOptions
          .map((option: any) => option.value)
          .some((value: any) => value === columnBaseSelectedOptions.value))
    ) {
      setMessage({
        type: "error",
        text: "No se pueden seleccionar columnas que ya están siendo usadas",
      });
    }
  } else {
    if (
      (selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions[0] &&
        hasRepeteadNumbers(
          selectedOptions.map((option: any) => option.value)
        )) ||
      (selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions[0] &&
        columnAxisXSelectedOptions &&
        selectedOptions
          .map((option: any) => option.value)
          .some((value: any) => value === columnAxisXSelectedOptions.value)) ||
      (selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions[0] &&
        columnAxisYSelectedOptions &&
        selectedOptions
          .map((option: any) => option.value)
          .some((value: any) => value === columnAxisYSelectedOptions.value)) ||
      (selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions[0] &&
        columnSeriesSelectedOptions &&
        selectedOptions
          .map((option: any) => option.value)
          .some((value: any) => value === columnSeriesSelectedOptions.value)) ||
      (columnAxisYSelectedOptions &&
        columnAxisXSelectedOptions &&
        columnAxisYSelectedOptions.value ===
          columnAxisXSelectedOptions.value) ||
      (columnSeriesSelectedOptions &&
        columnAxisXSelectedOptions &&
        columnSeriesSelectedOptions.value ===
          columnAxisXSelectedOptions.value) ||
      (columnSeriesSelectedOptions &&
        columnAxisYSelectedOptions &&
        columnSeriesSelectedOptions.value === columnAxisYSelectedOptions.value)
    ) {
      setMessage({
        type: "error",
        text: "No se pueden seleccionar columnas que ya están siendo usadas.",
      });
    }
  }
};

export const handleAxisYName = (
  chartType,
  axisYName,
  settedYName,
  columnAxisYSelectedOptions,
  functionSelectedOptions,
  setAxisYName,
  setSettedYName,
  axisYNameRef
) => {
  if (
    chartType === "BARRAS CON DRILLDOWN" ||
    chartType === "LINEAS" ||
    chartType === "SCATTERPLOT"
  ) {
    if (
      (!axisYName || axisYName === settedYName) &&
      columnAxisYSelectedOptions &&
      columnAxisYSelectedOptions.length > 0 &&
      functionSelectedOptions
    ) {
      if (columnAxisYSelectedOptions.length < 2) {
        setAxisYName(
          `${functionSelectedOptions.label} de ${columnAxisYSelectedOptions[0]?.label}`
        );
        setSettedYName(
          `${functionSelectedOptions.label} de ${columnAxisYSelectedOptions[0]?.label}`
        );
      } else {
        setAxisYName(`${functionSelectedOptions.label}`);
        setSettedYName(`${functionSelectedOptions.label}`);
      }
    } else if (
      (!columnAxisYSelectedOptions ||
        columnAxisYSelectedOptions?.length === 0 ||
        !functionSelectedOptions) &&
      axisYName === settedYName
    ) {
      setAxisYName("");
      axisYNameRef.value = "";
      setSettedYName("");
    }
  }
};

export const handleAxisXName = (
  axisXName,
  settedXName,
  columnAxisXSelectedOptions,
  setAxisXName,
  setSettedXName,
  axisXNameRef
) => {
  if ((!axisXName || axisXName === settedXName) && columnAxisXSelectedOptions) {
    setAxisXName(columnAxisXSelectedOptions.label);
    setSettedXName(columnAxisXSelectedOptions.label);
  } else if (!columnAxisXSelectedOptions && axisXName === settedXName) {
    setAxisXName("");
    axisXNameRef.value = "";
    setSettedXName("");
  }
};

export const filterDataIndicators = (
  data: any,
  value: string,
  setFilteredData: (value: any) => void
) => {
  if (value && data) {
    setFilteredData(
      data?.filter((object: any) => {
        return (
          object.nombre.toLowerCase().includes(value?.toLowerCase()) ||
          object.grupo.label.includes(value?.toLowerCase())
        );
      })
    );
  } else {
    data && setFilteredData(data);
  }
};

export const filterDataCharts = (
  data: any,
  value: string,
  setFilteredData: (value: any) => void
) => {
  if (value && data) {
    setFilteredData(
      data?.filter((chart: any) => {
        return (
          chart.nombre?.toLowerCase().includes(value?.toLowerCase()) ||
          chart.columna_eje_y?.label
            .toLowerCase()
            .includes(value?.toLowerCase()) ||
          chart.columna_eje_x?.label
            .toLowerCase()
            .includes(value?.toLowerCase())
        );
      })
    );
  } else {
    data && setFilteredData(data);
  }
};

export const chartListFiles: ChartOfList[] = [
  { image: BarrasDrilldown, type: "BARRAS CON DRILLDOWN" },
  { image: Lines, type: "LINEAS" },
  { image: DrilldownCake, type: "TORTA CON DRILLDOWN" },
  { image: TreeDataDrilldown, type: "TREEMAP CON DRILLDOWN" },
  { image: Boxplot, type: "BOXPLOT" },
];

export const chartListAnalysis: ChartOfList[] = [
  { image: BarrasDrilldown, type: "BARRAS CON DRILLDOWN" },
  { image: Lines, type: "LINEAS" },
  { image: DrilldownCake, type: "TORTA CON DRILLDOWN" },
  { image: TreeDataDrilldown, type: "TREEMAP CON DRILLDOWN" },
  { image: waterfall, type: "WATERFALL" },
  { image: scatterplot, type: "SCATTERPLOT" },
  { image: Boxplot, type: "BOXPLOT" },
];

export const handleElementRepeatedName = (
  t,
  name,
  elementsList,
  elementId,
  configMode,
  setMessage,
  dataGetTable?
) => {

  if (elementsList && name) {
    const normalizedInputName = name.trim().toLowerCase();
    const elementInfo = elementsList.find((an: any) => an.id === elementId);
    
    const elementNameExists = elementsList.find(
      (an: any) => an?.nombre?.trim().toLowerCase() === normalizedInputName
    );
    
    const isTableMode = dataGetTable && configMode === "CREATE";
    const isNameConflict = elementNameExists && (configMode === "CREATE" || configMode === "COPY");
    const isEditModeWithNameConflict = 
      elementNameExists &&
      configMode === "EDIT" &&
      elementInfo?.nombre?.trim().toLowerCase() !== normalizedInputName;
    
    if (isTableMode) {
      const elementCreated = elementsList.find((an: any) => an.id === dataGetTable.id);
      const elementNameExistsInTable = dataGetTable.nombre === name;
      
      if (elementCreated && elementNameExistsInTable) {
        setMessage("");
        return;
      }
    }
  
    if (isNameConflict || isEditModeWithNameConflict) {
      setMessage({ text: t("general.repeatedName"), type: "error" });
    } else {
      setMessage("");
    }
  }
};

export const mergeAndDeleteRepeatedColumns = (dataTable) => {
  const mergedColumns: any = [];
  if (dataTable?.columnas_disponibles?.agrupacion) {
    mergedColumns.push(...dataTable.columnas_disponibles.agrupacion);
  }
  if (dataTable?.columnas_disponibles?.medidas) {
    mergedColumns.push(...dataTable.columnas_disponibles.medidas);
  }
  const uniqueColumns = mergedColumns.filter(
    (column, index, self) =>
      index === self.findIndex((t) => t.value === column.value)
  );
  return uniqueColumns;
};

export const isRepeatedName = (name: string, rows: any[]) => {
  return !!rows?.find((row) => {
    return row.nombre.trim().toLowerCase() === name.trim().toLowerCase();
  });
};

export const validateNewColumnRow = (
  t,
  rows: any[],
  setMessage: any,
  column1: string,
  column2: SingleValue<IdOption> | undefined,
  columns3: MultiValue<IdOption> | undefined,
  columns4: MultiValue<IdOption> | undefined,
  valueSelectedOption: any
) => {
  let result = false;
  if (column1 !== "" && rows && rows.length > 0) {
    const repeatedName = isRepeatedName(column1, rows);
    if (repeatedName) {
      setMessage({
        type: "error",
        text: t("general.repeatedName"),
      });
      return false;
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }
  }
  if (column1 !== "" && column2) {
    const repeatedName = isRepeatedName(column1, rows);
    const repeatedRows =
      rows !== undefined &&
      rows.length > 0 &&
      (column2.value === "SUMAR" || column2.value === "MULTIPLICAR")
        ? rows?.filter((row) => {
            return (
              row.funcion.value === column2?.value &&
              row.columnas.some((columna) =>
                columns3?.some((column3) => column3?.value === columna.value)
              )
            );
          })
        : rows !== undefined &&
          rows.length > 0 &&
          (column2.value === "RESTAR" || column2.value === "DIVIDIR")
        ? rows?.filter((row) => {
            return (
              row.funcion.value === column2?.value &&
              row.columnas.some((columna) =>
                columns3?.some((column3) => column3?.value === columna.value)
              ) &&
              row.columnas_secundarias.some((columna) =>
                columns4?.some((column4) => column4?.value === columna.value)
              )
            );
          })
        : rows !== undefined && rows.length > 0 && column2.value === "VALOR"
        ? rows?.filter((row) => {
            return (
              row.funcion.value === column2?.value &&
              row.valor === valueSelectedOption
            );
          })
        : [];
    if (repeatedName) {
      setMessage({
        type: "error",
        text: t("general.repeatedRowsName"),
      });
      return false;
    }
    if (repeatedRows && repeatedRows.length > 0) {
      result = false;
      setMessage({
        type: "error",
        text: t("general.repeatedRowsError"),
      });
    } else {
      result = true;
    }
  } else {
    setMessage({ type: "error", text: t("general.emptyInputs") });
  }
  return result;
};

export const formatNewColumnRow = (
  columna1: string,
  columna2: SingleValue<IdOption> | undefined,
  columna3: MultiValue<IdOption> | undefined,
  columna4?: MultiValue<IdOption> | undefined,
  valueSelectedOption?: any
) => {
  return columna4
    ? {
        nombre: columna1,
        funcion: columna2,
        columnas: columna3,
        columnas_secundarias: columna4,
        valor:
          valueSelectedOption && valueSelectedOption !== ""
            ? valueSelectedOption
            : undefined,
      }
    : {
        nombre: columna1,
        funcion: columna2,
        columnas: columna3,
        valor:
          valueSelectedOption && valueSelectedOption !== ""
            ? valueSelectedOption
            : undefined,
      };
};

export const validateGroupingRow = (
  t,
  rows: any[],
  setMessage: any,
  columns1: MultiValue<IdOption> | undefined,
  column2: SingleValue<IdOption> | undefined
) => {
  let result = false;
  if (column2) {
    const repeatedRows =
      rows !== undefined && rows.length > 0
        ? rows?.filter((row) => {
            return (
              row.funcion.value === column2?.value &&
              row.columnas.some((columna) =>
                columns1?.some((column3) => column3?.value === columna.value)
              )
            );
          })
        : [];
    if (repeatedRows && repeatedRows.length > 0) {
      result = false;
      setMessage({
        type: "error",
        text: t("general.repeatedRowsError"),
      });
    } else {
      result = true;
    }
  } else {
    setMessage({ type: "error", text: t("general.emptyInputs") });
  }
  return result;
};

export const formatGroupingRow = (
  columnas1: MultiValue<IdOption> | undefined,
  columna2: SingleValue<IdOption> | undefined
) => {
  return {
    columnas: columnas1,
    funcion: columna2,
  };
};

export const validateFilterRowsRow = (
  t,
  rows: any[],
  setMessage: any,
  column1: SingleValue<IdOption> | undefined,
  column2: SingleValue<IdOption> | undefined,
  columns3: MultiValue<IdOption> | undefined
) => {
  let result = false;
  if (column1 && column2) {
    const repeatedRows =
      rows !== undefined && rows.length > 0
        ? rows?.filter((row) => {
            return (
              row.columna.value === column1?.value &&
              row.operador.value === column2?.value &&
              row.valores.some((valor) =>
                columns3?.some((column3) => column3?.value === valor.value)
              )
            );
          })
        : [];
    if (repeatedRows && repeatedRows.length > 0) {
      result = false;
      setMessage({
        type: "error",
        text: t("general.repeatedRowsError"),
      });
    } else {
      result = true;
    }
  } else {
    setMessage({ type: "error", text: t("general.emptyInputs") });
  }
  return result;
};

export const formatFilterRowsRow = (
  column1: SingleValue<IdOption> | undefined,
  column2: SingleValue<IdOption> | undefined,
  columns3: MultiValue<IdOption> | undefined
) => {
  return {
    columna: column1,
    operador: column2,
    valores: columns3,
  };
};

export const isLastRowTotalSum = (waterfallRows) => {
  return (
    waterfallRows &&
    waterfallRows[waterfallRows.length - 1]?.function?.value === "suma_total"
  );
};
