import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import Select from "../../forms/Select";
import { Stack } from "@mui/material";
import { selectStyles } from "../../criterias/configCriteria/select.styles";
import { IdOption, OnChange } from "../../criterias/configCriteria/types";
import { SingleValue } from "react-select";
import useLanguage from "../../../context/LanguageProvider";

interface AssignDriverModalProps {
    open: boolean;
    handleClose: () => void;
    handleFinish: () => void;
    prepareColumnsToUseOptions: any,
    variableSelectedOption: SingleValue<IdOption> | undefined,
    variableOptionChangeHandler: OnChange
}

const AssignDriverModal = ({
    open,
    handleClose,
    handleFinish,
    prepareColumnsToUseOptions,
    variableSelectedOption,
    variableOptionChangeHandler,
}: AssignDriverModalProps) => {

    const { t } = useLanguage()

    return (
        <Dialog
            open={open}
            handleClose={handleClose}
            title={t("deltaTexts.simulationRules.assignDriverModal.assignCostDriver")}
            maxWidth="xl"
            actions={
                <>
                    <Button
                        title={t("simulatorTexts.assignVariabilityModal.cancel")}
                        color="grey"
                        type="button"
                        onClick={handleClose}
                    />
                    <Button
                        title={t("simulatorTexts.assignVariabilityModal.accept")}
                        color="blue"
                        type="button"
                        disabled={variableSelectedOption === undefined}
                        onClick={handleFinish}
                    />
                </>
            }
        >
            <Stack sx={{
                width: "300px",
                height: "200px",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
                pt: 1,
                pl: 3
            }}>
                <Select
                    styles={selectStyles(variableSelectedOption)}
                    options={prepareColumnsToUseOptions && prepareColumnsToUseOptions()}
                    form="cc_form"
                    className="cc_select"
                    name="dependentVar"
                    onChange={variableOptionChangeHandler}
                    placeholder={t("simulatorTexts.assignVariabilityModal.dependentVariable")}
                    closeMenuOnSelect
                    data-testid="select_column_component"
                    isClearable
                />
            </Stack>
        </Dialog >
    )
}

export default AssignDriverModal;
